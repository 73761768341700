import React from 'react'
import ReactDOM from 'react-dom'
import SettingsGeneralInfo from './SettingsGeneralInfo'
import SettingsManageNotifications from './SettingsManageNotifications'
import Locals from 'd3_locals-lib'
import request from 'superagent'
import Spinner from './Spinner'
class Settings extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pathName: window.location.pathname,
      locals: Locals.locals('en', '/platform/settings')
    }
  }

  componentDidMount () {
    this._getCustomer()
    this._getCustomerOptions()
    window.portal.settings.onRouteChange(this._routeChange.bind(this), true)
  }

  _isNonCustomer (){
    return window.portalSession.affiliateConsumer.contactType && window.portalSession.affiliateConsumer.contactType === 'NON_CUSTOMER'
  }

  _getCustomer (){
    if(this._isNonCustomer()){
      this.setState({customer: window.portalSession.affiliateConsumer})
      return
    }
    let userInfoURL = `/api/v0/userinfo?includeAffiliate=true&includeMenuOptions=true&includeAppData=true&includeConsumerEvents=true&includeSMB=true&includeAuthToken=true&subdomain=${window.location.hostname}`

    request
      .get(userInfoURL)
      .end(function (err, res) {
        if (err) {
          console.log(err.response.error)
        } else {
          window.portalSession.affiliateConsumer.textablePhone = res.body.affiliateConsumers[0].textablePhone
          this.setState({customer: res.body.affiliateConsumers[0]})
        }
      }.bind(this))
  }

  _getCustomerOptions() {
    if(this._isNonCustomer()){
      return
    }
    let customerOptionIds = [10103, 19,17, 10102, 10105, 10106, 10107, 10202, 21]
    window.portalSession.optinConfig.map(function(option){
      option.optin.map(function(item){
        customerOptionIds.push(item.customerOption)
      })
    })
    
    let requestEmailStatusURL = `/api/v0/d3-api/v1/business/${window.portalSession.affiliateBusiness.id}/customer/${window.portalSession.affiliateConsumer.id}/emailOptinProcess`
    let requestURL = `/api/v0/d3-api/v1/business/${window.portalSession.affiliateBusiness.id}/customerOption?filter={"customerId":${window.portalSession.affiliateConsumer.id},"type":{"$in":[${customerOptionIds.join(', ')}]}}&projection=["id","type","value"]`

    request
      .get(requestEmailStatusURL)
      .end(function (err, res){
        if(err) {
          this.setState({
            emailOptinProcess: {
              optinStatus: null
            },
            emailOptinStatus: 'error'
          })
        }else{
          this.setState({
            emailOptinStatus: res.status,
            emailOptinProcess: res.body
          })
        }
      }.bind(this))

    request
      .get(requestURL)
      .end(function (err, res) {
        if (err) {
          this.setState({
            customerOptionsError: true
          })
        } else {
          this.setState({
            status: res.status,
            customerOptions: _.keyBy(res.body, 'type')
          })
        }
      }.bind(this))
  }

  _routeChange (path) {
    if (window.location.pathname !== path) {
      window.history.pushState({}, path, path)
    }

    this.setState({pathName: path})

    this._getCustomer()
    this._getCustomerOptions()
  }
  render () {
    const adminData = JSON.parse(Buffer.from(window.portalSession.auth.consumerPortalAuth, 'base64').toString('utf8'))
    let adminUserFlag = false
    if (adminData && adminData.authUser && adminData.authUser.userType && adminData.authUser.userType === 'admin') {
      adminUserFlag = true
    }

    if (!this.state.customer) {
      return (
        <div><div className='centerContainer'><Spinner size="lg" /></div></div>
      )
    } else {
      return (
        <div>
          <div className='settings-header'>
          <span className='settings-header-text'>
            {this.state.locals.accountSettings()}
          </span>
          </div>
          <div className='settings-menu'>
          <span
            className={(this.state.pathName === '/settings') ? 'highlighted-menu-option' : ''}
            onClick={this._routeChange.bind(this, '/settings')}
            >
            <span className='generalInfo'>
              {this.state.locals.generalInfo()}
            </span>
          </span>
            {!this._isNonCustomer() &&
            <span
              className={(this.state.pathName === '/settings/notification') ? 'highlighted-menu-option' : ''}
              onClick={this._routeChange.bind(this, '/settings/notification')}
              >
              <span className='desktop notification'>
                {this.state.locals.desktopNotification()}
              </span>
              <span className='mobile notification'>
                {this.state.locals.mobileNotification()}
              </span>
            </span>
            }
          </div>

          <SettingsGeneralInfo locals={this.state.locals} style={{display: this.state.pathName === '/settings/notification' ? 'none' : 'block'}} adminUserFlag={adminUserFlag} />
          {!this._isNonCustomer() &&
          <SettingsManageNotifications customer={this.state.customer} status={this.state.status}
                                       customerOptions={this.state.customerOptions}
                                       emailOptinStatus={this.state.emailOptinStatus}
                                       emailOptinProcess={this.state.emailOptinProcess}
                                       style={{display: this.state.pathName === '/settings/notification' ? 'block' : 'none'}}
                                       adminUserFlag={adminUserFlag} />
          }
        </div>
      )
    }

  }
}

export default Settings
