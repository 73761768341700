import React from 'react'

const AccessBlockedModal = ({done, dependentName}) => {
  return(
    <div className= "accessBlockedModal">
      <div>
        <div className='accessBlockedModal-title'>
          Access Blocked
        </div>
        <div>
          <p className='accessBlockedModal-description'>Your access to <b>{dependentName}'s</b> account is now blocked</p>
          <p className='accessBlockedModal-description'>To regain the access, please contact {window.portalSession.affiliateBusiness.name} at {window.portalSession.affiliateBusiness.mainPhone}.</p>
        </div>
        <div>
        <button onClick = {()=>{done()}} className="accessBlockedModal-close">OK, GOT IT</button>
        </div>
      </div>
    </div>
  )
}

export default AccessBlockedModal