import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faBell} from '@fortawesome/fontawesome-free-regular'
import {faAngleDown, faAngleUp} from '@fortawesome/fontawesome-free-solid'

const NotificationHub = ({showNotifications, toggle}) => {
  return (
      <div className='notification-section'>
        <span className='notification-section-header'>
          <span className='notification-bell-wrapper'><FontAwesomeIcon icon={faBell}/></span>
          <span className='notification-title-text'>Notifications</span>
          <FontAwesomeIcon className='angle-down' icon={toggle ? faAngleDown : faAngleUp} onClick={showNotifications}/>
        </span>
      </div>
  )
}
export default NotificationHub
