import React from 'react'

const MoreMenuModal = ({ modalName, routes, updateMainPath, updateModalName }) => (
  <div>
    <div className='more-menu-modal-background show-modal' />
    <div className='more-menu-modal show-modal'>
      <div className='more-menu-modal-content'>
        <ul className='more-menu__list'>
          {
          routes.map((route) => {
            return (
              <li className='menu__element--modal' key={route.routeURI}>
                <a
                  className={`menu__link icon-label ${route.routeURI}`}
                  onClick={() => {
                    updateMainPath(route)
                    updateModalName(null)
                  }}
                >
                  <span className='icon-label__icon' />
                  <span className='icon-label__label' />
                </a>
              </li>
            )
          })
        }
        </ul>
      </div>
    </div>
  </div>
)

export default MoreMenuModal
