export const TOGGLE_MENU = 'TOGGLE_MENU'
export const SET_MODAL_NAME = 'SET_MODAL_NAME'
export const SET_MAIN_PATH = 'SET_MAIN_PATH'
export const FIND_CURRENT_PATH = 'FIND_CURRENT_PATH'
export const LOAD_PLATFORM_REQUEST = 'LOAD_PLATFORM_REQUEST'
export const FETCH_NAVIGATION_ROUTES_REQUEST = 'FETCH_NAVIGATION_ROUTES_REQUEST'
export const FETCH_NAVIGATION_ROUTES_SUCCESS = 'FETCH_NAVIGATION_ROUTES_SUCCESS'
export const FETCH_NAVIGATION_ROUTES_FAILURE = 'FETCH_NAVIGATION_ROUTES_FAILURE'
export const FETCH_APP_REQUEST = 'FETCH_APP_REQUEST'
export const FETCH_APP_SUCCESS = 'FETCH_APP_SUCCESS'
export const FETCH_APP_FAILURE = 'FETCH_APP_FAILURE'
export const LOAD_APP_SUCCESS = 'LOAD_APP_SUCCESS'
export const LOAD_NOTFOUNDPAGE_REQUEST = 'LOAD_NOTFOUNDPAGE_REQUEST'