import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/fontawesome-free-regular'
import NotificationActions from './NotificationActions'
import Empty from '../../images/no-notifications-graphic.png'
import moment from 'moment-timezone'
class NotificationList extends React.Component {
  constructor (props) {
    super(props)
    this.renderNotifications = this.renderNotifications.bind(this)
    this.subRenderTime = this.subRenderTime.bind(this)
    this.sortAndSliceNotifications = this.sortAndSliceNotifications.bind(this)
  }
  subRenderTime (time, timezone) {
    const momentDate = moment(time).tz(timezone)
    const isCurrentDate = momentDate.isSame(new Date(), 'day')
    let format = 'hh:mm a'
    if (!isCurrentDate) {
      format = 'MMM DD'
    } else {
      format = 'hh:mm a'
    }

    if (!this.props.minified) {
      format = 'MMMM Do @ hh:mm a'
    }
    return (
      <span className='notification-time'>
        <FontAwesomeIcon icon={faClock} />
        <span>{momentDate.format(format)}</span>
      </span>
    )
  }

  sortAndSliceNotifications () {
    if (this.props.notifications.length === undefined) return null

    var sortNotifications = this.props.notifications.slice();
    sortNotifications.sort (function(a, b) {
          return b.time.isAfter(a.time);
      });

    var sliceSize;
    if (this.props.sliceSize)
        sliceSize = this.props.sliceSize;
    else
        sliceSize = this.props.notifications.length;

    var slicedNotifications = sortNotifications.slice(0, sliceSize);

    return slicedNotifications;
  }

  getRoute (link, menuList) {
    let route = null;
    menuList.map((menu) => {
      if (menu.routeURI == link) {
        route =  menu
      }
    });
    return route
  }

  handleClick(notification) {
    const { successResponse, updateMainPath, updateModalName } = this.props;
    let menuList = successResponse.toJS().menuOptions;
    let link = notification.link.replace(/\//, "");

    if (!notification.read) {
      window.portalSession.notifications.notificationToggleRead(notification);
    }
    
    updateMainPath(this.getRoute(link, menuList));
    updateModalName(null)
  }

  renderNotifications () {
    let _this = this;

    const timezone = this.props.successResponse.toJS().affiliateBusiness.timezone

    if (this.props.notifications.length === undefined) return null

    var purifiedNotifications = this.sortAndSliceNotifications();

    return purifiedNotifications.map((notification, index) => {
      let notificationBody = notification.body;
      if (this.props.minified && notificationBody.length > 70 ) {
        notificationBody = notificationBody.slice(0, 65).trim();
        notificationBody += '...';
      }

      return (
        <div key={index} className={'notification-item-container ' + ((notification.read) ? ' notification-item-container-read' : '')}
             onClick={ () => {_this.handleClick(notification)} }>
          <div className={'notification-item ' + ((notification.read) ? ' notification-read' : '')}>
            <span className='notification-item-header'>
              <span className='notification-type'>{notification.title}</span>
              {this.subRenderTime(notification.time, timezone)}
            </span>
            <div className='notification-message'>
              <span className='wrapped-text'>{notificationBody}</span>
            </div>
          </div>
        </div>
      )
    })
  }
  render () {
    return (
        <div className='notification-list'>
          {this.renderNotifications()}
          {(this.props.minified)
          ? (<div className='notification-wrapper-button'>
              {this.props.notifications.length
                ?null
                :<div className = "empty-notification">
                  <img src = {Empty}></img>
                  <span>All clear!</span>
                  <p>You don't have any notifications.</p>
                </div>
              }
            </div>)
          : null
          }
        </div>
    )
  }
}

export default NotificationList
