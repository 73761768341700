import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import cssVariables from 'css-vars-ponyfill';

import MenuContainer from '../containers/MenuContainer'
import Header from './Header'
import MoreMenuModal from './MoreMenuModal'
import WrapperComponent from './WrapperComponent'
import {setWrappersAndListeners} from '../utils/routingEvent'
import './localize'
import Spinner from './Spinner'
import Error404 from './Error404'
import InActiveUser from './InActiveUser'
import AppModal from './AppModal'

import graph from 'ib-graph';
import {ConfigSelect} from 'ib-graph';
// import {initPendoData} from '../utils/Pendo';
import {defaultThemes} from "../defaultThemes";

const configs = () => {

  let affiliateBusiness = !!window.portalSession.affiliateBusiness;
  let affiliateConsumer = !!window.portalSession.affiliateConsumer ? window.portalSession.affiliateConsumer : window.jsonData;

  return [
    {
      name: 'Live Data',
      config: {
        url: `/api/v0/ibgraph`
          }
        },
            {
      name: 'Test Data',
      key: affiliateConsumer.id,
      config: {
        user: {
          roles: ['consumer', 'patientPortalConsumer'],
          demandforce: {
            businessId: 130000622,
            customerId: 57717082
          }
        },
        data: {
          businesses: [
            {
              id: 130000622,
              name: 'Pet Superstore',
              textableLandLineAssignment: 16572206958,
              timezone: 'America/New_York'
            },
            {
              id: 130000029,
              name: 'Dental Superstore',
              textableLandLineAssignment: 16572206959,
              timezone: 'America/New_York'
            }
          ],
          businessOptions: [
            {
              id: 2758787,
              businessId: 130000029,
              optionType: 'IB_ONLINE_BOOKING',
              valueBoolean: true
            },
            {
              id: 2758788,
              businessId: 130000029,
              optionType: 'ONLINE_SCHEDULING',
              valueBoolean: false
            },
            {
              id: 2758789,
              businessId: 130000622,
              optionType: 'ONLINE_SCHEDULING',
              valueBoolean: false
            }
          ],
          customers: [
            {
              businessId: 130000622,
              id: 57717082,
              firstName: 'Janet',
              lastName: 'Liu',
              email: 'jliu@gmail.com'
            },
            {
              businessId: 130000029,
              id: 196359161,
              firstName: 'tommyt',
              lastName: '02394be5e518e52c8885f5bcb219ff68',
              email: 'a5410ee37744c574ba5790034ea08f79@charleshimes.com'
            }
          ],
          pets: [
            {
              businessId: 130000622,
              customerId: 57717082,
              id: 8928765,
              image: null,
              name: 'Princess',
              breed: 'Russian Blue',
              species: 'Feline',
              color: 'Blue',
              weight: 60,
              weightUnits: 'lbs',
              gender: 'Female',
              birthdate: '2004-04-21',
              status: 'ACTIVE',
              lastVisitDate: moment().subtract(1, 'days').valueOf(),
            },
            {
              businessId: 130000622,
              customerId: 57717082,
              id: 6810342,
              image: null,
              name: 'Freddy',
              breed: 'Persian',
              species: 'Cat',
              color: 'Hazelnut',
              weight: 50,
              weightUnits: 'lbs',
              gender: 'Male',
              status: 'ACTIVE',
              birthdate: '2001-04-21',
              lastVisitDate: moment().subtract(2, 'days').valueOf(),
            },
            {
              businessId: 130000029,
              customerId: 196359161,
              id: 8928285,
              image: null,
              name: 'Spot',
              breed: 'Domestic Shorthair',
              species: 'Feline',
              color: 'Hazelnut',
              weight: 50,
              weightUnits: 'lbs',
              gender: 'Male',
              status: 'ACTIVE',
              birthdate: '2001-04-21',
              lastVisitDate: moment().subtract(2, 'days').valueOf(),
            }
          ],
          appointments: [
            {
              id: 89292,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              scheduledDate: moment().subtract(1, 'days').valueOf(),
              createdDate: moment().subtract(1, 'days').valueOf(),
              status: 'SCHEDULED',
              businessProviderId: 'a8981',
              services: 'Skin allergy check'
            },
            {
              id: 89293,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              scheduledDate: moment().subtract(2, 'days').valueOf(),
              createdDate: moment().subtract(2, 'days').valueOf(),
              status: 'CONFIRMED',
              businessProviderId: 'a8981',
              services: 'Teeth Cleaning'
            },
            {
              id: 89294,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              scheduledDate: moment().subtract(3, 'days').valueOf(),
              createdDate: moment().subtract(3, 'days').valueOf(),
              status: 'SCHEDULED',
              businessProviderId: 'a8981',
              services: 'Blood Test'
            },
            {
              id: 89295,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              scheduledDate: moment().subtract(4, 'days').valueOf(),
              createdDate: moment().subtract(4, 'days').valueOf(),
              status: 'SCHEDULED',
              businessProviderId: 'a8981',
              services: 'Vaccination'
            },
            {
              id: 89296,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              scheduledDate: moment().add(2, 'days').valueOf(),
              createdDate: moment().subtract(5, 'days').valueOf(),
              status: 'SCHEDULED',
              businessProviderId: 'a8981',
              services: 'Ear Cleaning'
            },
            {
              id: 89297,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              scheduledDate: moment().subtract(5, 'days').valueOf(),
              createdDate: moment().subtract(6, 'days').valueOf(),
              status: 'REQUESTED',
              businessProviderId: 'a8982',
              services: 'Annual Checkup'
            },
            {
              id: 89298,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              scheduledDate: moment().add(1, 'days').valueOf(),
              createdDate: moment().subtract(7, 'days').valueOf(),
              status: 'SCHEDULED',
              businessProviderId: 'a8981',
              services: 'Annual Health Checkup'
            },
            {
              id: 89299,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 8928765,
              scheduledDate: moment().add(2, 'days').valueOf(),
              createdDate: moment().subtract(8, 'days').valueOf(),
              status: 'SCHEDULED',
              businessProviderId: 'a8981',
              services: 'Annual Routine Checkup'
            }
          ],
          reminders: [
            {
              businessId: 130000622,
              appointmentId: 89294,
              reminderType: 'SMS_CONFIRMATION',
              channel: 'SMS',
              reminderStatus: 'SENT',
              reminderDate: 1558032013383
            }
          ],
          providers: [
            {
              businessId: 130000622,
              id: 778928282,
              firstName: 'Kathy',
              lastName: 'Chen',
              businessProviderId: 'a8981'
            },
            {
              businessId: 130000622,
              id: 778928282,
              firstName: 'Ryan',
              lastName: 'Chen',
              businessProviderId: 'a8982'
            }
          ],
          recommendations: [
            {
              id: 1252367,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              description: 'Canine Rabies Vaccine 3 year',
              recommendationType: null,
              status: 'ACTIVE',
              recommendationDate: moment().subtract(2, 'month').startOf('day').valueOf(),
              dueDate: moment().add(2, 'month').startOf('day').valueOf(),
            },
            {
              id: 1252337,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              description: 'Canine Rabies Vaccine 1 year',
              recommendationType: 'Canine Rabies Vaccine 1 year',
              status: 'ACTIVE',
              recommendationDate: moment().subtract(2, 'month').startOf('day').valueOf(),
              dueDate: moment().add(2, 'month').startOf('day').valueOf(),
            },
            {
              id: 123982227,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              status: 'INACTIVE',
              description: 'Canine DHLPP Annual',
              recommendationType: null,
              recommendationDate: moment().subtract(4, 'month').startOf('day').valueOf(),
              dueDate: null,
            },
            {
              id: 34252334,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              status: 'ACTIVE',
              description: 'Semi-Annual Visit and Exam',
              recommendationType: 'Semi-Annual Visit and Exam',
              recommendationDate: moment().subtract(5, 'month').startOf('day').valueOf(),
              dueDate: moment().add(1, 'month').startOf('day').valueOf()
            },
            {
              id: 34252378,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              status: 'ACTIVE',
              description: 'Office Visit and Exam',
              recommendationType: null,
              recommendationDate: moment().subtract(6, 'month').startOf('day').valueOf(),
              dueDate: moment().add(1, 'month').startOf('day').valueOf()
            },
            
            {
              id: 56252362,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 8928765,
              status: 'ACTIVE',
              customerDismissed: false,
              description: 'Office Visit and Exam',
              recommendationType: 'Office Visit and Exam',
              recommendationDate: moment().subtract(2, 'month').startOf('day').valueOf(),
              dueDate: moment().add(3, 'month').startOf('day').valueOf()
            },
            {
              id: 2930592,
              customerId: 57717082,
              entityId: 6810342,
              businessId: 130000622,
              customerDismissed: false,
              dueDate: 1344574800000,
              recommendationDate: moment().subtract(2, 'month').startOf('day').valueOf(),
              description: 'Canine Bordetella Vaccination',
              status: 'ACTIVE'
            },
            {
              id: 2930590,
              customerId: 57717082,
              entityId: 6810342,
              customerDismissed: false,
              businessId: 130000622,
              dueDate: 1344574800000,
              description: 'Canine Rabies Vaccination 1 Ye',
              recommendationDate: moment().subtract(2, 'month').startOf('day').valueOf(),
              status: 'ACTIVE'
            },
            {
              id: 2930593,
              customerId: 57717082,
              customerDismissed: false,
              entityId: 6810342,
              businessId: 130000622,
              dueDate: 1600785082000,
              recommendationDate: moment().subtract(2, 'month').startOf('day').valueOf(),
              description: 'Canine Bordetella Vaccination',
              status: 'ACTIVE'
            },
            {
              id: 2930589,
              customerId: 57717082,
              entityId: 6810342,
              customerDismissed: false,
              businessId: 130000622,
              dueDate: 1344574800000,
              description: 'Canine Rabies Vaccination 1 Ye',
              recommendationDate: moment().subtract(2, 'month').startOf('day').valueOf(),
              status: 'ACTIVE'
            },
            {
              id: 2930588,
              customerId: 57717082,
              entityId: 6810342,
              customerDismissed: false,
              businessId: 130000622,
              dueDate: 1598970682000,
              description: 'Canine Bordetella Vaccination',
              recommendationDate: moment().subtract(2, 'month').startOf('day').valueOf(),
              status: 'ACTIVE'
            }
          ],
          visits: [
            {
              id: 54343762,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              serviceDescription: 'Canine DHLPPC Annual',
              category: 'V: Vaccination, Canine',
              businessProviderId: 'a8981',
              visitDate: 1282280400000
            },
            {
              id: 54343532,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              serviceDescription: 'St Charles Tag, Intact Pet',
              category: 'VR: Vaccination, Rabies',
              businessProviderId: 'a8981',
              visitDate: 1285563600000
            },
            {
              id: 54343542,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              serviceDescription: 'Rabies Vaccination 1 Year Canine, #T4428',
              category: 'VR: Vaccination, Rabies',
              businessProviderId: 'a8981',
              visitDate: 1285563600000
            },
            {
              id: 54343702,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              serviceDescription: 'St Charles Tag, Altered Pet',
              category: 'VR: Vaccination, Rabies',
              businessProviderId: 'a8981',
              visitDate: 1313038800000
            },
            {
              id: 54343712,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              serviceDescription: 'Canine Bordetella Vaccination Inj.',
              category: 'V: Vaccination, Canine',
              businessProviderId: 'a8981',
              visitDate: 1313038800000
            },
            {
              id: 54343722,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              serviceDescription: 'Canine DHLPPC Annual',
              category: 'V: Vaccination, Canine',
              businessProviderId: 'a8981',
              visitDate: 1313038800000
            },
            {
              id: 54343812,
              businessId: 130000622,
              customerId: 57717082,
              entityId: 6810342,
              serviceDescription: 'Rabies Vaccination 1 Year Canine, #59479',
              category: 'VR: Vaccination, Rabies',
              businessProviderId: 'a8981',
              visitDate: 1313038800000
            },
            {
              id: 419216221,
              businessId: 130000029,
              customerId: 196359161,
              entityId: 6810342,
              serviceDescription: 'Rabies Vaccination 1 Year Canine',
              category: 'VR: Vaccination, Rabies',
              businessProviderId: 'a8981',
              visitDate: 1381294800000
            },
            {
              id: 419216231,
              businessId: 130000029,
              customerId: 196359161,
              entityId: 6810342,
              serviceDescription: 'Canine DHLPPC Annual',
              category: 'V: Vaccination, Canine',
              businessProviderId: 'a8981',
              visitDate: 1381294800000
            },
            {
              id: 4192162316,
              businessId: 130000029,
              customerId: 196359161,
              entityId: 6810342,
              serviceDescription: 'Canine DHLPPC Annual',
              category: 'V: Vaccination, Canine',
              businessProviderId: 'a8981',
              visitDate: 1381294800000
            },
            {
              id: 4192162315,
              businessId: 130000029,
              customerId: 196359161,
              entityId: 8928765,
              serviceDescription: 'Canine DHLPPC Annual',
              category: 'V: Vaccination, Canine',
              businessProviderId: 'a8981',
              visitDate: 1381294800000
            },
            {
              id: 4192162314,
              businessId: 130000029,
              customerId: 196359161,
              entityId: 8928765,
              serviceDescription: 'Canine DHLPPC Month',
              category: 'V: Vaccination, Canine',
              businessProviderId: 'a8981',
              visitDate: 1381294800000
            },
            {
              id: 4192162313,
              businessId: 130000029,
              customerId: 196359161,
              entityId: 8928765,
              serviceDescription: 'Canine DHLPPC Month',
              category: 'V: Vaccination, Canine',
              businessProviderId: 'a8981',
              visitDate: 1381294800000
            }
          ]
        }
      }
    }
  ]
}

class App extends React.Component {
  constructor (props) {
    super(props)
    this.findCurrentPath = this.findCurrentPath.bind(this)
    this.changeRouteRequest = this.changeRouteRequest.bind(this)
    this.showAllNotifications = this.showAllNotifications.bind(this)
    this.removeConfigSelect = this.removeConfigSelect.bind(this);
  }

  componentWillMount() {

    //use polyfill if browser is IE or Edge
    const ua = window.navigator.userAgent
    const isIE = /MSIE|Trident/.test(ua)
    if ( isIE ) {
      let theme = ''
      if(window.location.hostname.indexOf('mypetnexus') !== -1) {
        theme = 'pet-portal-theme'
      } else if (!!window.portalSession && window.portalSession?.portalBusiness?.brand === 'dfsesame') {
        theme = 'sesame-portal-theme' 
      } else {
        theme = 'patient-portal-theme'
      }
      cssVariables({
        watch: true,
        variables: defaultThemes[theme],
        onlyLegacy: false
      })
    }
  }

  componentDidMount () {
    const { findCurrentPathLocation, loadPlatformData, changeRoute} = this.props

    // Add event listeners to call when user goes back and forth through history
    setWrappersAndListeners()

    // Listens for any route changes
    window.addEventListener('popstate', this.findCurrentPath)
    window.addEventListener('pushstate', this.findCurrentPath)

    window.addEventListener('routeChangeRequest', this.changeRouteRequest, false)

    // Set up window object
    window.portal = {}

    // Load platform
    loadPlatformData()
  }

  changeRouteRequest (event) {
    const {changeRoute, routesLoaded, successResponse } = this.props
    changeRoute(successResponse.toJS().menuOptions, event.detail.app, event.detail.appPath, event.detail.source)
  }

  findCurrentPath () {
    const { findCurrentPathLocation, routesLoaded, successResponse } = this.props
    findCurrentPathLocation(routesLoaded, successResponse.toJS().menuOptions)
  }

  showAllNotifications () {
    const {successResponse, updateMainPath, updateModalName} = this.props

    let menuOptions = successResponse.toJS().menuOptions
    updateModalName(null)
    updateMainPath(_.find(menuOptions, {routeURI: 'notifications'}))
  }

  removeConfigSelect() {
    localStorage.removeItem('graphConfigSelect');
    graph.configure(liveConfig.config);
    this.forceUpdate();
  }

  configSelect(selected) {
    localStorage.setItem('graphConfigSelect', selected);
  }

  render () {
    const { loading, menuCollapsed, modalName, mainPath, successResponse,
      updateMainPath, updateModalName, pageNotFound } = this.props
    let menuOptions = successResponse.toJS().menuOptions
    let inActiveUser = window.jsonData?.err === 'affiliateConsumerNotFound' && !!window.portalSession
    // if(successResponse && successResponse.size>0 && (typeof pendo === 'undefined')){
    //   initPendoData(successResponse)
    // }
    if(window.location.pathname === '/petdemo') {
      return (
        <div style={{padding: 15}}>
          <Customer/>
        </div>
      );
    }
    return (
      <div>
        {
        (pageNotFound)
        ? <Error404 />
        : (!!inActiveUser )
        ? <InActiveUser /> 
        : (loading)
          ? <div className='centerContainer'><Spinner size="lg" /></div>
          : <div
            className={(menuCollapsed) ? 'collapsed' : ''}
          >
            <ConfigSelect
              configs={configs()}
              enable={window.location.href.indexOf('graphconfig7459123409713247324') !== -1} // super secret url to turn on
              localStorage={`ibgraphConfigSelect.${window.portalSession.affiliateBusiness.id}.${window.portalSession.affiliateConsumer.id}`}
              disabledConfiguration='Live Data'/>
            <AppModal/>
            <MenuContainer
              updateMainPath={updateMainPath}
            />
            <Header modalName={modalName} showAllNotifications={this.showAllNotifications} updateModalName={updateModalName} businessName={successResponse.toJS().affiliateBusiness.name} consumerInfo={successResponse.toJS().affiliateConsumer} successResponse={successResponse} updateMainPath={updateMainPath} />
            <WrapperComponent mainPath={mainPath} />
            {
              modalName === 'more-menu' &&
                <MoreMenuModal
                  routes={_.filter(menuOptions, (menuOption) => {return menuOption.menuTitle}).slice(3)}
                  updateMainPath={updateMainPath}
                  updateModalName={updateModalName}
                />
            }
          </div>
        }
      </div>

    )
  }
}

export default App
