import React from 'react';
import Dropdown from './Dropdown';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/fontawesome-free-solid'
import { faCheck, faInfoCircle } from '@fortawesome/fontawesome-free-solid'
import AccessBlockedModal from './AccessBlockedModal';
import {createAccount, switchAccount} from '../utils/dependentHelper'

class ResponsiblePartyUserInfo extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen : true
    }
    this.openDropdown = this._openDropdown.bind(this);
    this.isLoggedIn = this._isLoggedIn.bind(this);
    !!window.sessionStorage.getItem('modalClosed') ? window.sessionStorage.getItem('modalClosed') : window.sessionStorage.setItem('modalClosed', false);
  }
  _openDropdown(){
    this.setState({isOpen: !this.state.isOpen})
  }
  
  _isLoggedIn(id){
    return parseInt(window.portalSession.affiliateConsumer.id) === parseInt(id)
  }

  hasNoAccess(){
    let hasNoAccess = false
    let hasNoAccessArray
     hasNoAccessArray = window.portalSession.responsible.responsibleForCustomers.map((dependents)=>{
      if(dependents.blocked) {
        return true
      }else if(!dependents.patientPortalId){
        return true
      }else return false
     })
     return hasNoAccessArray.indexOf(true) !== -1
  }

  render(){
    let loggedInUser = window.portalSession.responsible.firstName +' '+ window.portalSession.responsible.lastName
    return(
      !!window.portalSession.responsible.responsibleForCustomers.length &&
      <div className='responsiblePartyUserInfo'>
        <span className='responsiblePartyUserInfo-loggedInUser' onClick={this.openDropdown}>{window.portalSession.affiliateConsumer.firstName}</span>
        <FontAwesomeIcon className='responsiblePartyUserInfo-caret' icon={this.state.isOpen ? faAngleDown : faAngleUp} onClick={this.openDropdown} />
        <div className={this.state.isOpen ? 'responsiblePartyUserInfo-displayDependents' : 'responsiblePartyUserInfo-showDropdown responsiblePartyUserInfo-displayDependents'}> 
        <div className='responsiblePartyUserInfo-patientPortalAccess'>
          <div className='responsiblePartyUserInfo-selectName'>
            <div className='responsiblePartyUserInfo-dependentInfo'>
              <FontAwesomeIcon className={this.isLoggedIn(window.portalSession.responsible.id) ? 'responsiblePartyUserInfo-dependentInfo-check' : 'responsiblePartyUserInfo-dependentInfo-check hide'} icon={faCheck}/>
              <p onClick={()=>{switchAccount(window.portalSession.responsible.patientPortalId),this.openDropdown()}} className='responsiblePartyUserInfo-dependentInfo-name desktopViewName'>{loggedInUser}</p>
              <p onClick={()=>{switchAccount(window.portalSession.responsible.patientPortalId),this.openDropdown()}} className='responsiblePartyUserInfo-dependentInfo-name mobileViewName'>{loggedInUser.length > 20 ? loggedInUser.substr(0,20).concat('...') : loggedInUser}</p>
            </div>
          </div>
          {
            window.portalSession.responsible &&
            window.portalSession.responsible.responsibleForCustomers.map((dependent)=>{
              return (
                !dependent.blocked && dependent.patientPortalId &&
                  <div className='responsiblePartyUserInfo-selectName'>
                    <div className='responsiblePartyUserInfo-dependentInfo'>
                      <FontAwesomeIcon className={this.isLoggedIn(dependent.id) ? 'responsiblePartyUserInfo-dependentInfo-check' : 'responsiblePartyUserInfo-dependentInfo-check hide'} icon={faCheck}/>  
                      <p onClick={()=>{switchAccount(dependent.patientPortalId),this.openDropdown()}} className='responsiblePartyUserInfo-dependentInfo-name desktopViewName'>{(dependent.firstName+' '+dependent.lastName)}</p>
                      <p onClick={()=>{switchAccount(dependent.patientPortalId),this.openDropdown()}} className='responsiblePartyUserInfo-dependentInfo-name mobileViewName'>{(dependent.firstName+' '+dependent.lastName).length > 15 ? (dependent.firstName+' '+dependent.lastName).substr(0,15).concat('...'): (dependent.firstName+' '+dependent.lastName)}</p>
                    </div>
                  </div>
              )
            })
          }
          </div>
          <div className={this.hasNoAccess() ? 'responsiblePartyUserInfo-patientPortalNoAccess' : 'responsiblePartyUserInfo-patientPortalNoAccess showNone'} >
          {
            window.portalSession.responsible &&
            window.portalSession.responsible.responsibleForCustomers.map((dependent)=>{
              return (
                !dependent.blocked && !dependent.patientPortalId &&
                  <div className='responsiblePartyUserInfo-selectName'>
                    <div className='responsiblePartyUserInfo-dependentInfo'>
                      <FontAwesomeIcon className={'responsiblePartyUserInfo-dependentInfo-check hide'} icon={faCheck}/>
                      <p className='responsiblePartyUserInfo-dependentInfo-name desktopViewName'>{(dependent.firstName+ ' ' +dependent.lastName)}</p>
                      <p className='responsiblePartyUserInfo-dependentInfo-name mobileViewName'>{(dependent.firstName+' '+dependent.lastName).length > 15 ? (dependent.firstName+' '+dependent.lastName).substr(0,15).concat('...'): (dependent.firstName+' '+dependent.lastName)}</p>
                    </div>
                    <p onClick={()=>{createAccount([dependent.id]),this.openDropdown()}} className='createAcc'>Create Account</p>
                  </div>
              )
            })
          }
          {
            window.portalSession.responsible &&
            window.portalSession.responsible.responsibleForCustomers.map((dependent)=>{
              return (
                dependent.blocked &&
                  <div className='responsiblePartyUserInfo-selectName'>
                    <div className='responsiblePartyUserInfo-dependentInfo'>
                      <FontAwesomeIcon className={'responsiblePartyUserInfo-dependentInfo-check hide'} icon={faCheck}/>
                      <p className='responsiblePartyUserInfo-dependentInfo-name desktopViewName'>{(dependent.firstName+ ' ' +dependent.lastName)}</p>
                      <p className='responsiblePartyUserInfo-dependentInfo-name mobileViewName'>{(dependent.firstName+' '+dependent.lastName).length > 15 ? (dependent.firstName+' '+dependent.lastName).substr(0,15).concat('...'): (dependent.firstName+' '+dependent.lastName)}</p>
                    </div>
                    <p onClick={()=>{let done = window.portalSession.modals.showModal(<AccessBlockedModal dependentName={dependent.firstName +" "+ dependent.lastName} done={() => {done()}}/>);}} className='accessRestricted'>Access Restricted <FontAwesomeIcon className='accessRestricted-icon' icon={faInfoCircle}/></p>
                  </div>
              )
            })
          }
          </div>
        </div>
      </div>
    )
  }
}

export default ResponsiblePartyUserInfo
