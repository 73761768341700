import React from 'react'
import _ from 'lodash'

class Menu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { modalName, moreMenuOptions, successResponse,
      toggleMenuCollapse, updateMainPath, updateModalName, mainPath } = this.props

    let menuList = successResponse.toJS().menuOptions

    // hide menu options with no menuTitle
    menuList = _.filter(menuList, (menuOption) => {return menuOption.menuTitle})

    console.log(menuList)

    return (
      <div className={(this.state.collapsed ? 'collapsed' : '')}>
        <div className='menu'>
          <ul className='menu__container'>
            <li className='menu__element--desktop'>
              <a
                href='#'
                className='menu__trigger icon-label hamburger'
                onClick={toggleMenuCollapse}
              >
                <span className='icon-label__icon' />
              </a>
            </li>
            {
              menuList.map((route, index) => {
                return (
                  <li className={`menu__element ${(index < 3 || (index == 3 && menuList.length == 4) ? '' : ' mobile-modal')} ` +
                    `${route.routeURI === mainPath && modalName !== 'more-menu' ? 'active' : ''}`}
                    key={route.routeURI}>
                    <a className={`menu__link icon-label menu__position ${route.routeURI}`}
                      onClick={() => { updateMainPath(route); updateModalName(null) }}
                    >
                      <span className='icon-label__icon' />
                      <span className='icon-label__label' />
                    </a>
                  </li>
                )
              })
            }
            {
              moreMenuOptions && <li className='menu__element--mobile mobile-modal'>
                <a
                  className={`menu__link icon-label more-menu menu__position ${modalName === 'more-menu' ? 'active' : ''}`}
                  onClick={() => {
                    (modalName === 'more-menu')
                      ? updateModalName(null)
                      : updateModalName('more-menu')
                  }}
                  >
                  <span className='icon-label__icon' />
                  <span className='icon-label__label' />
                </a>
              </li>
            }

          </ul>
        </div>
      </div>
    )
  }
}

export default Menu
