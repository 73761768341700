import React from 'react';
import Welcome from './../../images/welcome-avatar.svg'
import request from 'superagent'
import CheckBox from './CheckBox';
import Close from './../../images/Rectangle 22.svg';
import {createAccount} from '../utils/dependentHelper'
class DependentModal extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.handleCheck = this._handleCheck.bind(this)
    let checked = window.portalSession.responsible.responsibleForCustomers.filter((res) => {
      return !!res.patientPortalId
    }).map(res => {
      return res.id
    })
    this.state = {
      checkBoxChange : false,
      createAccountFor: checked,
      modalClosed: !!window.sessionStorage.getItem('modalClosed') ? window.sessionStorage.getItem('modalClosed') : 'false'
    }
    if (this.state.modalClosed === 'true') {
      this.props.done()
    }
  }
  
  submit(){
    createAccount(this.state.createAccountFor)
    this.props.done()
  }

  onClose(event){
    let requestURL = `/api/v0/navigation/consumers/${window.portalSession.portalConsumer.consumerId}`
    request
      .put(requestURL)
      .send({rpClose:true})
      .then(this.props.done())
  }

  onRemind(event){
    sessionStorage.setItem('remindModal',true)
    this.props.done()
  }

  _handleCheck(bid, option){
    if(this.state.createAccountFor.indexOf(bid) == -1){
      if(option === 'add'){
        let createAccountFor = this.state.createAccountFor;
        createAccountFor.push(bid)
        this.setState({createAccountFor})
      }
    }else{
      if(option === 'remove'){
        let createAccountFor = this.state.createAccountFor;
        createAccountFor.splice(this.state.createAccountFor.indexOf(bid),1)
        this.setState({createAccountFor})
      }
    }
  }
  ShortenName(name){
    if(name.length > 19){
      return name.substr(0,16).concat('...')
    }else return name
  }
  render() {
    let consumerName = window.portalSession.consumerType
    let providerName = window.portalSession.providerType
    const CONSUMER_NAME = {
      uc: consumerName.charAt(0).toUpperCase() + consumerName.slice(1),
      lc: consumerName.toLowerCase()
    }
    const PROVIDER_NAME = {
      uc: providerName.charAt(0).toUpperCase() + providerName.slice(1),
      lc: providerName.toLowerCase()
    }

    return (
      <div className= "dependentModal">
        <div onClick={()=>{this.onClose()}}><img className='dependentModal-closeModal' src={Close}/></div>
      <div>
      <div> 
        <p className = "dependentModal-highlightText">Create an Account for Your Dependents</p>
        <p className = "dependentModal-highlightDes">Select the dependents that you want to create an account for.</p>
        <div className='dependentModal-listContainer'>
          <div className='dependentModal-dependentList'>
          {
            window.portalSession.responsible && 
            window.portalSession.responsible.responsibleForCustomers.map((dependents)=>{
            return(
              !dependents.blocked &&
              <div className='dependentModal-container'>
                <CheckBox
                  isChecked={!!dependents.patientPortalId}
                  bid={dependents.id}
                  onChecked={this.handleCheck}
                >
                </CheckBox>
                <span className='dependentModal-dependentName'>{this.ShortenName(dependents.firstName+' '+dependents.lastName)}</span>
              </div>
            )  
            })
          }
          </div>
        </div>
      <div>
        <button disabled={!this.state.createAccountFor.length ? true : false} onClick = {this.submit} className="dependentModal-createAcc">CREATE ACCOUNT</button>
      </div> 
        <a onClick = {()=>{this.onRemind()}}>Remind Me Later</a>
      </div>
      </div>
    </div>
    );
  }
}
export default DependentModal