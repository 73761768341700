import React from 'react'
import Icon from '@fortawesome/react-fontawesome'

class ButtonSelect extends React.Component {
  constructor (props) {
    super(props)
    this.onToggle = this.onToggle.bind(this)
    this.renderList = this.renderList.bind(this)
    this.state = {
      open: false
    }
  }
  onToggle () {
    this.setState((state) => {
      state.open = !state.open
    })
  }
  renderList () {
    return this.props.options.map((obj) => {
      return (
        <li key={obj.id} className={(obj.selected) ? 'active' : ''} onClick={() => { this.props.onSelect(obj.id); this.onToggle() }} >
          <div>
            <Icon icon={'check'} />
            <span>{obj.text}</span>
          </div>
        </li>
      )
    })
  }
  render () {
    return (
      <div className='button--select--wrapper'>
        <div className='button--select' onClick={this.onToggle}>
          <span>Change Location</span>
          <div className='switch-icons'>
            <Icon icon={'angle-up'} className='top' />
            <Icon icon={'angle-down'} className='down' />
          </div>
        </div>
        <div className={'switch-dropdown ' + ((this.state.open) ? 'open' : '')}>
          <ul>
            {this.renderList()}
          </ul>
        </div>
      </div>
    )
  }
}

export default ButtonSelect
