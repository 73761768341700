import $ from 'jquery'

var DFPP = {
  init: function () {
    this.registerEvents()
  },
  registerEvents: function () {
    this.registerHamburgerToggle()
    this.registerMoreMenuModalToggle()
    this.registerFormInputEdit()
    this.reigsterUserNotificationsModalToogle()
    this.registerMoreMenuItemClick()
  },
  registerHamburgerToggle: function () {
    $('.menu__trigger').on('click', function (event) {
      $('.menu').parent().toggleClass('collapsed')
      event.preventDefault()
    })
  },
  registerMoreMenuModalToggle: function () {
    $('.more-menu, .more-menu-modal-background').on('click', function (event) {
      event.preventDefault()
      $('.more-menu-modal, .more-menu-modal-background').toggleClass('show-modal')
    })
  },

  registerMoreMenuItemClick: function () {
    $('.menu__element--modal .menu__link').on('click', function (event) {
      event.preventDefault()
      alert('navigate to ...' + $(this).prop('class'))
      $('.more-menu-modal, .more-menu-modal-background').toggleClass('show-modal')
    })
  },

  registerFormInputEdit: function () {
    $('.save-button').on('click', function (event) {
      event.preventDefault()
    })

    $('.edit-cancel-button').on('click', function (event) {
      event.preventDefault()
      var inputElement = $(this).parent().parent().find('input')
      if ($(this).text() === 'Edit') {
        $(this).parent().removeClass('buttons-container')
        $(this).parent().addClass('save-cancel-buttons')
        inputElement.removeClass('formatted-input')
        $(this).text('Cancel')
        $(this).prev().css('display', 'inline')
        for (var i = 0; i < inputElement.length; i++) {
          if (inputElement[i].className === 'editable') {
            inputElement[i].attributes.removeNamedItem('disabled')
          }
        }
        if ($(this).parent().parent().hasClass('address-form')) {
          $('.address-form label').addClass('shown')
          $('.address-form label').removeClass('address-label-shown')
        }
      } else if ($(this).text() === 'Cancel') {
        $(this).parent().removeClass('save-cancel-buttons')
        $(this).parent().addClass('buttons-container')
        $(this).text('Edit')
        $(this).prev().css('display', 'none')
        inputElement.addClass('formatted-input')
        inputElement.attr('disabled', true)
        if ($(this).parent().parent().hasClass('address-form')) {
          $('.address-form label').removeClass('shown')
          $('.address-form label').addClass('address-label-shown')
        }
      }
    })
  },

  reigsterUserNotificationsModalToogle: function () {
    $('.user-notification, .user-notification-modal-overlay').on('click', function () {
      $('.user-notification-modal, .user-notification-modal-overlay').toggleClass('show-modal')
    })
  }
}
$(document).ready(function () {
  DFPP.init()
})
