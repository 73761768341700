import React from 'react'
import ReactDOM from 'react-dom'
import validator from 'validator'
import ToggleSwitch from './ToggleSwitch'
import _ from 'lodash'
import { saveTextOptin } from '../utils/saveNotificationInformation'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/fontawesome-free-solid'
import PhoneInput, { format, normalize } from "react-phone-input-auto-format";
import FlashMessage from './FlashMessage';

class TextMessages extends React.Component {
  constructor (props) {
    super(props)
    this.savePhoneNotification = this.savePhoneNotification.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.subRenderPhoneInput = this.subRenderPhoneInput.bind(this)
    this.optIn = this.optIn.bind(this)
    this.masterToggle = this.masterToggle.bind(this)
    this.renderOptIns = this.renderOptIns.bind(this)
    this.maskPhoneNumber = this.maskPhoneNumber.bind(this)
    const consumer = window.portalSession.affiliateConsumer
    this.state = {
      PhoneNumberError: false,
      changed: false
    }
  }

  componentDidMount () {
    const formatedPhone = this.props.customer.textablePhone ? this.props.customer.textablePhone : this.props.customer.cellPhone
    this.setState({
      phone: formatedPhone ? format(formatedPhone) : null
    })
  }

  componentWillReceiveProps(nextProps) {
    const formatedPhone= nextProps.customer.textablePhone? nextProps.customer.textablePhone : nextProps.customer.cellPhone
    this.setState({
      phone: formatedPhone ? format(formatedPhone) : null
    })
  }
  masterToggle () {
    let customerOptions = _.cloneDeep(this.props.customerOptions)
    let masterOption = customerOptions[17]
    let customerOption = customerOptions[10202]
    let smsRminder = (customerOption || {}).value || '0'
    let smsStatus = (masterOption || {}).value || '0'
    this.props.onToggle.apply(this, [17, smsStatus])
    this.setState({changed: true})
    if (customerOption.value === masterOption.value) {
      this.props.onToggle.apply(this, [10202, smsRminder])
    }
    
  }
  optIn () {
    this.savePhoneNotification().then((body) => {
      
      this.setState({changed: true})
      this.props.setCustomerOption(17, '1')
      this.props.onToggle.apply(this, [10202, smsRminder])
    }).catch((error) => {
      console.log('getting to optinCatch somehow', error)
    })
  }
  savePhoneNotification () {
    let consumerId = window.portalSession.affiliateConsumer.id
    let businessId = window.portalSession.affiliateBusiness.id
    return saveTextOptin(businessId, consumerId, this.state.phone).then((body) => {
      this.setState((state) => {
        setTimeout(() => {
          this.setState({saved: false})
        }, 3000)
        return {
          saved: true,
          PhoneNumberError: false
        }
      })
      console.log('trace', body)
      return body
    }).catch((errorObj) => {
      console.log('stack trace:', errorObj)
      this.setState((state) => {
        return {
          saved: false,
          PhoneNumberError: true
        }
      })
      throw errorObj
    })
  }
  hideMessage(){
    this.setState({changed: false});
  }
  onChangeInput(event) {
    console.log(event.target.value);
    const val = format(event.target.value);
    this.setState((state) => {
      return {
        phone: val
      }
    })
  }
  renderOptIns (disabled) {
    let smsRminder = (this.props.customerOptions[10202] || {}).value || '0'
    let smsStatus = (this.props.customerOptions[17] || {}).value || '0'
    let renderOpts = null

    if (!disabled) {
      renderOpts = (
        <div>
          <br />
          <div className='setting-text'>Opt-In Status</div>
          <div className='setting-text font-weight-normal'>Configure your opt-in status</div>
          <ToggleSwitch
            name='Status'
            onChange={this.masterToggle}
            value={smsStatus == '1' ? 'on' : 'off'}
            text='Status'
          />
          <br />
          <div className='setting-text'>Text Messages Types</div>
          <div className='setting-text font-weight-normal separate-line padding1'>Turn on the notification you wish to receive</div>
          <ToggleSwitch
            name='smsRminder'
            onChange={() => { this.props.onToggle.apply(this, [10202, smsRminder])}}
            value={smsRminder == '1' ? 'on' : 'off'}
            text='Appointment Reminders'
          />
          <br />
        </div>
      )
    }
    return renderOpts
  }

  maskPhoneNumber (phoneNumber) {
    return phoneNumber ? phoneNumber.replace(/[0-9]/g, 'X') : ''
  }

  subRenderPhoneInput (smsStatus, checkMark) {
    const disabled = (smsStatus !== '1')
    const phoneError = this.state.PhoneNumberError
    const { adminUserFlag } = this.props
    return (
      <div className='margin1 text-phone-input'>
        { (phoneError)
          ? <span className='alt-text'>Please enter a valid phone number</span>
          : null
        }

        {adminUserFlag 
          ? <input className={'disabledInputWidth ' + ((phoneError) ? 'input-error' : '')}
            value={this.maskPhoneNumber(this.state.phone)} 
            onChange={this.onChangeInput} 
            placeholder='(xxx) xxx-xxxx'
            maxLength = '14'
            disabled
            />
          : <input 
              className={'disabledInputWidth ' + ((phoneError) ? 'input-error' : '')} 
              value={this.state.phone} 
              onChange={this.onChangeInput} 
              placeholder='(xxx) xxx-xxxx'
              maxLength = '14'
            />
        }

        {(!disabled)
        ? <button id='text-phone-input-button' disabled={adminUserFlag} className={'button--primary save-changes ' + ((checkMark) ? 'saved-phone' : '')} onClick={this.savePhoneNotification}>Done</button>
        : <button id='text-phone-input-button' disabled={adminUserFlag} className={'button--primary save-changes ' + ((checkMark) ? 'saved-phone' : '')} onClick={this.optIn}>Opt In</button>
        }
        { (!disabled && this.state.saved)
        ? <FontAwesomeIcon icon={(!phoneError) ? faCheck : null} />
        : null
        }
        { this.renderOptIns(disabled)}
      </div>
    )
  }
  render () {
    let smsRminder = (this.props.customerOptions[10202] || {}).value || '0'
    let smsStatus = (this.props.customerOptions[17] || {}).value || '0'
    const checkMark = this.state.saved
    let term = 'By clicking Opt In you consent to being contacted at the mobile number you provided (including by autodialer and text msg). We will send you appointment reminders via text msg. Msg and data rates may apply. Text "HELP" for help or "STOP" to unsubscribe. Consent is not required as a condition of the services.'

    return (
      <div>
        {
          this.state.changed ?
          <FlashMessage message = {'You\'ve successfully opted '+ ((smsStatus === '0') ? 'out of' : 'in to')+' text notifications'} hideMessage={this.hideMessage.bind(this)} type="text"/>
          :null
        }
        <div className='setting-container separate-line'>
          <span>Text Messages</span>
          <div className='setting-content'>
            <div className='setting-text'>You will receive text messages at</div>
            <div className='setting-text font-weight-normal padding1'>{term}</div>
            {this.subRenderPhoneInput(smsStatus, checkMark)}
            
          </div>
        </div>
      </div>
    )
  }
}

export default TextMessages
