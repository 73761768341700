import React from 'react'
import Icon from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/fontawesome-free-solid'
class PasswordValidationUI extends React.Component {
  constructor (props) {
    super(props)

    this.characterLength = this.characterLength.bind(this)
    this.specialChars = this.specialChars.bind(this)
    this.oneNumber = this.oneNumber.bind(this)
    this.oneUppercase = this.oneUppercase.bind(this)
    this.oneLowercase = this.oneLowercase.bind(this)
  }

  characterLength (value) {
    if (value && value.length >= 8 && value.length <= 20) {
      return true
    } else {
      return false
    }
  }
  specialChars (value) {
    return /.*([^a-zA-Z\d]).*/.test(value)
  }
  oneNumber (value) {
    return /.*[0-9].*/.test(value)
  }
  oneUppercase (value) {
    return /.*[A-Z].*/.test(value)
  }

  oneLowercase (value) {
    return /.*[a-z].*/.test(value)
  }

  render () {
    const password = this.props.password || ''
    const charLength = this.characterLength(password)
    const specialChars = this.specialChars(password)
    const oneNumber = this.oneNumber(password)
    const oneUppercase = this.oneUppercase(password)
    const oneLowercase = this.oneLowercase(password)
    return (
      <div className='password-validation-ui'>
        <ul className='list-inline bullet-list'>
          <li id='character-length' className={(charLength) ? 'checked' : 'error'} >
            <Icon icon={(charLength) ? faCheck : faTimes} />
            <span>8-20 characters in length</span>
          </li>
          <li id='special-characters' className={(specialChars) ? 'checked' : 'error'}>
            <Icon icon={(specialChars) ? faCheck : faTimes} />
            <span>At least 1 of these special characters: !@#$%^&*</span>
          </li>
          <li id='numbers' className={(oneNumber) ? 'checked' : 'error'}>
            <Icon icon={(oneNumber) ? faCheck : faTimes} />
            <span>At least 1 number</span>
          </li>
          <li id='uppercase' className={(oneUppercase) ? 'checked' : 'error'}>
            <Icon icon={(oneUppercase) ? faCheck : faTimes} />
            <span>At least 1 uppercase letter</span>
          </li>
          <li id='lowercase' className={(oneLowercase) ? 'checked' : 'error'}>
            <Icon icon={(oneLowercase) ? faCheck : faTimes} />
            <span>At least 1 lowercase letter</span>
          </li>
        </ul>
      </div>
    )
  }
}

export default PasswordValidationUI ;