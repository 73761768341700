import 'babel-polyfill'
import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import createLogger from 'redux-logger'
import Immutable from 'immutable'
import createSagaMiddleware from 'redux-saga'
import reducer from './reducers'
import rootSaga from './sagas'
import AppContainer from './containers/AppContainer'
import '../scss/main.scss'

// Create saga middleware
const sagaMiddleware = createSagaMiddleware()

// Create logger middleware (logs immutable objects as plain JS)
const logger = createLogger({
  stateTransformer: (state) => {
    let newState = {}

    for (var i of Object.keys(state)) {
      if (Immutable.Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS()
      } else {
        newState[i] = state[i]
      }
    }

    return newState
  }
})

// Remove logger from production
const middleware = !process.env.ENABLE_LOGGER
  ? [ sagaMiddleware ]
  : [ sagaMiddleware, logger ]

// Create store with reducers and middleware
const store = createStore(
  reducer,
  applyMiddleware(...middleware)
)

// Start running saga middleware
sagaMiddleware.run(rootSaga)

render(<Provider store={store}>
  <AppContainer />
</Provider>,
  document.getElementById('platform-app')
)

function idleLogout() {
  let t;
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;  // catches touchscreen presses as well      
  window.ontouchstart = resetTimer; // catches touchscreen swipes as well 
  window.onclick = resetTimer;      // catches touchpad clicks as well
  window.onkeypress = resetTimer;   
  window.addEventListener('scroll', resetTimer, true); // improved; see comments

  function logout() {
    window.portalSession.logout();
  }

  function resetTimer() {
    clearTimeout(t);
    t = setTimeout(logout, 1800000);  // time is in milliseconds
  }
}
idleLogout();
