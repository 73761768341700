import React from 'react'
import ReactDOM from 'react-dom'
import validator from 'validator'
import { saveConsumer } from '../utils/saveConsumer'

class AccountInfo extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      editEmail: false,
      validEmail: true
    }
    this.onChange = this.onChange.bind(this)
    this.maskEmail = this.maskEmail.bind(this)
  }

  editAccountInfo () {
    let consumer = window.portalSession.affiliateConsumer
    this.setState({
      editEmail: true,
      email: consumer.email
    })
  }

  onChange (type, event) {
    this.setState({
      [type]: event.target.value.trim(),
      validEmail: true
    })
  }

  validateEmail () {
    let email = this.state.email
    if (email === '' || !validator.isEmail(email)) {
      return false
    }
    return true
  }

  saveEmail () {
    if (this.validateEmail()) {
      let update = {email: this.state.email}

      saveConsumer(update, function (err, res) {
        if (!err) {
          Object.assign(window.portalSession.affiliateConsumer, update)
          this.setState({ editEmail: false, serverError: false })
        } else {
          this.setState({ serverError: true })
        }
      }.bind(this))
    } else {
      this.setState({ validEmail: false })
    }
  }

  maskEmail (email) {
    if (email && email.split('@').length > 1) {
      return '*'.repeat(email.split('@')[0].length) + '@' + email.split('@')[1]
    }
    return email
  }

  render () {
    let locals = this.props.locals._component('/platform/settings/accountInfo')
    let adminUserFlag = this.props.adminUserFlag
    return (
      <div>
        <div className='setting-container separate-line'>
          <span>{locals.accountInfo()}</span>
          {this.state.editEmail ? null : <span className='edit mobile' onClick={this.editAccountInfo.bind(this)}>Edit</span>}
          {this.state.editEmail
            ? <div className='setting-content'>
              <div className='setting-text'>{locals.loginEmail()}</div>
              <div><input disabled='disabled' value={window.portalSession.portalConsumer.email} /></div>
              <div className='setting-text'>{locals.communicationEmail()}</div>
              <div><input onChange={this.onChange.bind(this, 'email')} value={this.state.email} /></div>
              {this.state.validEmail ? null : <div className='form__help-text show-error'>Invalid Email</div>}
              {this.state.serverError ? <div className='form__help-text show-error'>There is something wrong. Please try again.</div> : null }
              <br />
              <div>
                <a className='button--primary desktop' onClick={this.saveEmail.bind(this)}>{locals.save()}</a>
                <a className='cancel desktop' onClick={() => { this.setState({ editEmail: false, validEmail: true, serverError: false }) }}>{locals.cancel()}</a>
              </div>
            </div>
          : <div className='setting-content'>
            <div className='setting-text'>{locals.loginEmail()}</div>
            <div>{adminUserFlag ? this.maskEmail(window.portalSession.portalConsumer.email) : window.portalSession.portalConsumer.email}</div>
            <br />
            <div className='setting-text'>{locals.communicationEmail()}</div>
            <div>{adminUserFlag ? this.maskEmail(window.portalSession.affiliateConsumer.email) : window.portalSession.affiliateConsumer.email}</div>
            <br />
            <div><a className='button--secondary edit desktop' onClick={this.editAccountInfo.bind(this)}>{locals.edit()}</a></div>
          </div>
          }
        </div>
      </div>
    )
  }
}

export default AccountInfo
