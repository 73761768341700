import request from 'superagent'

const url = '/api/v0/'

export function createAccount(bidsArray){
  let requestURL = url+`createresponsibledependents`
    let body = {customerIds : bidsArray}
    request
      .post(requestURL)
      .send(body)
      .then((res)=>{
        location.reload(true)
        window.sessionStorage.setItem('modalClosed', true);
        return res
      })
      .catch((err)=>{
        window.sessionStorage.setItem('modalClosed', false);
        return err
      })
}

export function switchAccount(bid){
  let requestURL = url+`switchresponsibleaccount`
  let body = {consumerId : bid}
  console.log(requestURL)
    request
      .post(requestURL)
      .send(body)
      .then((res)=>{
        location.reload(true)
        return res
      })
      .catch((err)=>{
        alert(err)
        return err
      })
}

