import { fromJS } from 'immutable'
import * as types from '../actionTypes/index'

const initialMenuState = fromJS({
  collapsed: false
})

export default function menu (state = initialMenuState, action) {
  switch (action.type) {
    case types.TOGGLE_MENU:
      return state.set('collapsed', !state.get('collapsed'))
    default:
      return state
  }
}
