import React from 'react'
import _ from 'lodash'
import notFoundError from '../../images/404.png'

class Error404 extends React.Component {



  render() {
    //const {} = this.props
    return (
    	<div className='centerContainer errorContainer'>
				<div>
					<img src={notFoundError} /><br/>
					<span className='errorBoldFont'>Oh no… Something went wrong</span><br/>
					<span className='errorFont'>The link may have been mistyped or the page has been moved.</span><br/><br/>
					<a className='errorLink' onClick={() => { window.history.go(-1) }}>RETURN TO PREVIOUS PAGE</a>
				</div>
			</div>
    )
  }
}

export default Error404
