import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import UserNotificationModal from './UserNotificationModal'
import ResponsiblePartyUserInfo from './ResponsiblePartyUserInfo'

class Header extends React.Component {
  constructor (props) {
    super(props)
    // temporary code
    this.sortAndSliceNotifications = this.sortAndSliceNotifications.bind(this)
    // temporary code
    this.state = {
      notifications: window.portalSession.notifications.notifications
    }
  }
  componentDidMount () {
    window.portalSession.notifications.addNotificationListener(this.setState.bind(this))

    let headerDiv = ReactDOM.findDOMNode(this).children[2]
    let headers = window.portalSession.headerConfig
    for(let i = 0; i < headers.length; i++) {
      if(window.portal[headers[i]]) {
        headerDiv.appendChild(window.portal[headers[i]].headerDiv)
      }
    }
  }

  /* Temporary code to count the notification count */
  sortAndSliceNotifications () {
    if (this.state.notifications.length === undefined) return null
    var sortNotifications = this.state.notifications.slice()
    sortNotifications.sort(function (a, b) {
      return b.time.isAfter(a.time)
    })
    var slicedNotifications = sortNotifications.slice(0, 5)

    return slicedNotifications
  }
  render () {
    let { modalName, showAllNotifications, updateModalName, businessName, timezone, consumerInfo, successResponse, updateMainPath } = this.props
    let numberOfNotifications = _.filter(this.state.notifications, {read: false}).length
    numberOfNotifications = _.filter(this.sortAndSliceNotifications(), {read: false}).length
    return (
      <div className='header'>
        <div className='practice-name'>
          <span className ='desktopViewName'>{businessName}</span>
        </div>
        <div className='header-user-info'>
        <ResponsiblePartyUserInfo/>
        <div
          className='user-notification user-notification--alert-visible'
          onClick={() => {
            (modalName === 'user-notification')
              ? updateModalName(null)
              : updateModalName('user-notification')
          }}
        >
          { numberOfNotifications > 0
          ? <span className='header-new-notifications'>{ (numberOfNotifications < 5) ? numberOfNotifications : 5}</span>
          : null
          }
        </div>
        </div>
        { modalName === 'user-notification' && <UserNotificationModal timezone={timezone} consumerName={consumerInfo.firstName + ' ' + consumerInfo.lastName} consumerEmail={consumerInfo.email} notifications={this.state.notifications} showAllNotifications={showAllNotifications} successResponse={successResponse} updateMainPath={updateMainPath} updateModalName={updateModalName} /> }
        <div className='header-apps' id='header-apps'/>
        <div className='user-notification-modal-overlay' />
      </div>
    )
  }
}

export default Header
