import React from 'react'
import ReactDOM from 'react-dom'
import validator from 'validator'
import ToggleSwitch from './ToggleSwitch'
import _ from 'lodash'
import Address from './Address'
import { appEnabled } from '../utils/helpers'

class Postcards extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    let consumer = window.portalSession.affiliateConsumer

    let address = consumer.address1 + ' ' + consumer.city + ' ' + consumer.state + ' ' + consumer.postalCode
    let postcardEnabled = (this.props.customerOptions[21] || {}).value || '0'
    let showPostcard = appEnabled('DFAppointment') && appEnabled('DFContact') && appEnabled('DFPostcards') // show only when contacts 2.0 and appointments are turned on
    const { adminUserFlag } = this.props

    return showPostcard ? <Address label='Postcards' sublabel='You will receive postcards at' adminUserFlag={adminUserFlag} /> : ''
  }
}

export default Postcards
