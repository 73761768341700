import request from 'superagent'
/**
 * // d3-api_url/business/:businessId/customer/:customerId/textoptin
 *  method: post
 * body:
 {
    "textableNum" : 9202548789 required.
    "optIn" : {"mcs":"true", "d3":"true"} optional. If not available, will not opt-in to d3 or mcs
    "sendVerificationText" : "true"/"false" optional. If not available, will send verification text.
 }
 if the request is sent successfully, then the status code is 200:
 if the number is not textable, the response is like this:
 {
 "code" : 403,
 "errorMessage" : "Phone number is not textable"
 }
 All other error will be status 500.
 */

export function saveTextOptin (businessId, customerId, phone) {
  let requestURL = `/api/v0/d3-api/v1/business/${businessId}/customer/${customerId}/textOptinProcess`
  let body = {
    'textableNum': phone,
    saveTextNumberTo: {'d3': 'true'},
    'optIn': {'d3': 'true'},
    sendVerificationText: 'false'
  }

  return request
    .post(requestURL)
    .send(body)
}
