import React from 'react'
import NotificationHub from './NotificationHub'
import NotificationList from './NotificationList'
import moment from 'moment'
import _ from 'lodash'
import UserIcon from '../../images/path-4.svg'
import PpUserIcon from '../../images/pp-path-4.svg'
// import userIcon from '../../../images/user-icon.svg'


/*let notifications = [
  {
    title: 'Secure Message',
    body: 'You have a new Secure Message from Dr. Brown',
    link: '/somelink',
    time: moment(),
    read: false
  },
  {
    title: 'PatientForm.',
    body: 'You have a new Patient Form from Dr. Brown',
    link: '/somelink',
    time: moment().subtract(5, 'days'),
    read: true
  },
  {
    title: 'Appointment',
    body: 'You have a new Upcoming Appointment on Aug 31st',
    link: '/somelink',
    time: moment().subtract(15, 'days'),
    read: true
  }
]*/

// curl -X POST -H "Authorization: bearer FJF839DHUJSIDH3893SHJDHDHSHJ69DW346II" -H "Content-Type: application/json" -d '{"consumerId":"2","app":"messaging","type":"newMessage","data":{"title":"New Message","body":"You have a new message.","link":"/messages/7"},"eventTime":"2018-02-13T08:09:37.663Z"}' http://localhost:8000/api/v0/navigation/consumerevents

class UserNotificationModal extends React.Component {
  constructor (props) {
    super(props)
    this.showNotifications = this.showNotifications.bind(this)
    this.state = {
      showNotifications: false
    }
  }
  showNotifications () {
    this.setState({showNotifications: !this.state.showNotifications})
  }
  render () {
    let {  successResponse, updateMainPath, updateModalName, consumerName, notifications, showAllNotifications } = this.props
    // if industryName is vet then use PpUserIcon or else UserIcon
    const userIcon = (window.portalSession.portalBusiness.industryName === 'vet')
    return (
      <div className='user-notification-modal show-modal'>
        <div className='user-notification-modal_head'>
          <div className='user-notification-modal__user-pic-container'>{userIcon ? <PpUserIcon/> : <UserIcon/>}</div>
          <span className='user-notification-modal__user-name'>{consumerName}</span>
          <span className='user-notification-modal__user-email'>{window.portalSession.portalConsumer.email}</span>
        </div>
        <NotificationHub showNotifications={this.showNotifications} toggle={this.state.showNotifications} />
        {(this.state.showNotifications)
          ? null:
          <NotificationList sliceSize={5} minified={1} notifications={notifications} showAllNotifications={showAllNotifications} successResponse={successResponse} updateMainPath={updateMainPath} updateModalName={updateModalName} />

        }
        <div className='user-notification-modal_footer'>
          <div className='user-notification-modal__row'>
            <a className='icon-label forward-arrow'
              onClick={window.portalSession.logout}>
              <span className='icon-label__icon' />
              <span className='icon-label__label' />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default UserNotificationModal
