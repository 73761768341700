import React from 'react';
import Welcome from './../../images/welcome-avatar.svg'
import request from 'superagent'

class WelcomeModal extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
  }
  submit(event){
    let requestURL = `/api/v0/navigation/consumers/${window.portalSession.portalConsumer.consumerId}`
    request
        .put(requestURL)
        .send({firstLogin:true})
        .then(this.props.done())
  }
  render() {
    let portalName = window.portalSession.portalType
    let providerName = window.portalSession.providerType
    const PORTAL_NAME = {
      uc: portalName.charAt(0).toUpperCase() + portalName.slice(1),
      lc: portalName
    }
    const PROVIDER_NAME = {
      uc: providerName.charAt(0).toUpperCase() + providerName.slice(1),
      lc: providerName
    }
    return (
      <div className= "welcomeModal">
          <div>
            <span className = "welcomeText">Welcome To {PORTAL_NAME.uc} {portalName === 'pet' ? 'Nexus' : 'Portal'}</span>  
          </div>
          <div>
            <Welcome/>
          </div>
          <div>
            <p className = "welcomeDes">With {PORTAL_NAME.uc} {portalName === 'pet' ? 'Nexus' : 'Portal'}, you can connect with your {portalName === 'pet' ? 'veterinarian' : PROVIDER_NAME.lc} through a convenient, safe, and secure environment.</p>  
          </div>
          <div>
            <button onClick = {this.submit} className="startBtn">START</button>
          </div> 
      </div>
    );
  }
}
export default WelcomeModal
