import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/fontawesome-free-solid'

class FlashMessage extends React.Component {
    constructor(props){
        super(props);
    }
    
    render() {
        return (
            <div className='alt-changed'>
                <span><FontAwesomeIcon icon={faExclamationCircle}/>{this.props.message}</span>
                <span onClick = {this.props.hideMessage}>x</span>
            </div> 
        )      
    }
}



export default FlashMessage
