import React from 'react'
import ReactDOM from 'react-dom'
import ToggleSwitch from './ToggleSwitch'

class AppSettings extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    return (
      <div>
        {
          window.portalSession.optinConfig.map((element)=>(
            window.portalSession.apps[element.app] ?
            (<div className='setting-container separate-line'>
              <span>{element.sectionTitle}</span>
              <div className='setting-content'>
                <div className='setting-text font-weight-normal padding1'>{element.text}</div>
                <div>
                  {element.optin.map((optin)=>{
                    let value = (this.props.customerOptions[optin.customerOption] || {}).value || '0'

                    return (
                      <ToggleSwitch
                      name={element.app}
                      onChange={this.props.onToggle.bind(this, optin.customerOption, value)}
                      value={ value == '1' ? 'on' : 'off'}
                      text={optin.title}
                      />
                    )
                  })}
                </div>
                <br />
              </div>
            </div>)
            : null
          ))
        }
      </div>
    )
  }
}

export default AppSettings
