import { fromJS } from 'immutable'
import * as types from '../actionTypes/index'

const initialRoutingState = fromJS({
  mainPath: window.location.pathname.split('/')[1],
  pathName: window.location.pathname,
  successResponse: {},
  loading: true,
  pageNotFound: false,
  routesLoaded: {},

})

export default function routing (state = initialRoutingState, action) {
  switch (action.type) {
    case types.SET_MAIN_PATH:
      return state
        .set('mainPath', action.payload.mainPath)
        .set('pathName', action.payload.pathName)
    case types.FIND_CURRENT_PATH:
      return state
        .set('mainPath', window.location.pathname.split('/')[1])
        .set('pathName', window.location.pathname)
    case types.FETCH_NAVIGATION_ROUTES_FAILURE:
      console.error('Failed to fetch navigation routes')
      return state
    case types.LOAD_NOTFOUNDPAGE_REQUEST:
    console.log('LNFR')
    return state
        .set('pageNotFound', true)
    case types.FETCH_NAVIGATION_ROUTES_SUCCESS:
      return state
        .set('successResponse', fromJS(action.successResponse))
    case types.LOAD_APP_SUCCESS:
      return state
        .setIn(['routesLoaded', action.routeName], true)
    case types.FETCH_APP_SUCCESS:
      return state
        .set('pageNotFound', false)
        .set('loading', false)
        .setIn(['routesLoaded', action.routeName], true)
        .set('mainPath', `${action.routeName}`)
        .set('pathName', `\\${action.routeName}`)
    default:
      return state
  }
}
