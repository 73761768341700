import React from 'react'
import _ from 'lodash'
import inActiveUser from '../../images/inActiveUser.png'
import { numberToPhone } from '../utils/helpers'


function InActiveUser() {
	let busPhoneNum = !!window.portalSession?.affiliateBusiness?.mainPhone ? numberToPhone(window.portalSession?.affiliateBusiness?.mainPhone) : '0000000000'
	let busName = !!window.portalSession?.affiliateBusiness?.name ? window.portalSession?.affiliateBusiness?.name : 'Business Name'
	let street = !!window.portalSession?.affiliateBusiness?.address1 ? window.portalSession?.affiliateBusiness?.address1 : 'Address'
	let city = !!window.portalSession?.affiliateBusiness?.city ? window.portalSession?.affiliateBusiness?.city : 'City'
	let state = !!window.portalSession?.affiliateBusiness?.state ? window.portalSession?.affiliateBusiness?.state : 'State'
	let zipCode = !!window.portalSession?.affiliateBusiness?.postalCode ? window.portalSession?.affiliateBusiness?.postalCode : 'Zip Code'
	
	return (
		<div className='in-active-user' >
			<div className='in-active-user__boxLeft'>
				<div className='in-active-user__header'>In-active User</div>
				<br></br>
				<div className='in-active-user__subText'>An error occurred while trying to login with an Inactive account.</div>
				<div className='in-active-user__subText'>Please contact our office for further assistance <span className='in-active-user__subText-num'>{busPhoneNum}.</span></div>

				<div className='in-active-user__btnContainer'>
					{!!window.portalSession.logoutUrl ? <a className='in-active-user__btn' href={window.portalSession.logoutUrl} onClick={window.portalSession.logout} >Return to Previous Page </a> : ''}
				</div>

				<div className='in-active-user__busData'>
					<div className='in-active-user__busName'>{busName}</div>
					<div className='in-active-user__street'>{street}</div>
					<div className='in-active-user__city'>{city}, {state}</div>
					<div className='in-active-user__zip'>{zipCode}</div>
					<div className='in-active-user__busPhoneNum'>{busPhoneNum}</div>
				</div>
			</div>
			<div className='in-active-user__boxRight'>
				<img src={inActiveUser} className='in-active-user__img' draggable="false"></img>
			</div>

		</div>
	)
}

export default InActiveUser
