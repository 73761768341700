import React from 'react';
import Welcome from './../../images/check-mark-graphic.png'

class PasswordSubmitModal extends React.Component {
  constructor(props) {
    super(props); 
  }

  render() {
    return (
      <div>
           <div className= "PasswordSubmitModal">
          <div>
            <img src={Welcome}/>
          </div>
          <div>
            <p className = "PasswordDes">Your Password Has Been Submitted</p>  
          </div>
          <div>
            <button onClick = {()=>{this.props.done()}} className="DoneBtn">DONE</button>
          </div> 
      </div>
      </div>
    );
  }
}

export default PasswordSubmitModal