import React from 'react'
import ReactDOM from 'react-dom'
import Locals from 'd3_locals-lib'
import { numberToPhone } from '../utils/helpers'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/fontawesome-free-solid'
import {faCommentDots} from '@fortawesome/fontawesome-free-regular'

class Dashboard extends React.Component {
  constructor (props) {
    super(props)
    this.handleRenderBusinessInfo = this.handleRenderBusinessInfo.bind(this)
    this.checkIfNumbersAreTheSame = this.checkIfNumbersAreTheSame.bind(this)
  }

  componentDidMount () {
    // window.portalSession.portalBusiness.tagline = "We are commited to helping you look, Fell, And Smile your best!"
    let root = ReactDOM.findDOMNode(this).children[1]

    let dashboardLeft = document.createElement('div')
    let dashboardRight = document.createElement('div')

    let divCounts = {};
    // left
    let hasLeft = false
    let column = window.portalSession.dashboardConfig[0]
    for(let j = 0; j < column.length; j++) {
      let appName = column[j].trim();
      let divCount = divCounts[appName] = (divCounts[appName] || 0) + 1;

      if(divCount === 1) {
        divCount = '';
      }

      let app = window.portal[appName]
      if(app && app[`dashboardDiv${divCount}`]) {
        dashboardLeft.appendChild(app[`dashboardDiv${divCount}`])
        hasLeft = true
      }
    }

    // right
    let hasRight = false
    column = window.portalSession.dashboardConfig[1]
    for(let j = 0; j < column.length; j++) {
      let appName = column[j].trim();
      let divCount = divCounts[appName] = (divCounts[appName] || 0) + 1;

      if(divCount === 1) {
        divCount = '';
      }

      let app = window.portal[appName]
      if(app && app[`dashboardDiv${divCount}`]) {
        dashboardRight.appendChild(app[`dashboardDiv${divCount}`])
        hasRight = true
      }
    }

    if(hasLeft && hasRight) {
      dashboardLeft.className = 'dashboardLeft'
      dashboardRight.className = 'dashboardRightContainer'

      root.appendChild(dashboardLeft)

      let righContainer = document.createElement('div')
      righContainer.className = 'dashboardRight'
      righContainer.appendChild(dashboardRight)

      root.appendChild(righContainer)
    }
    else if(hasLeft) {
      dashboardLeft.className = 'dashboardFull'
      root.appendChild(dashboardLeft)
    }
    else if(hasRight) {
      dashboardRight.className = 'dashboardFull'
      root.appendChild(dashboardRight)
    }
  }
  
  checkIfNumbersAreTheSame (textableNumber, formattedPhone) {
    return this.removeAreaCode(textableNumber) === this.removeAreaCode(formattedPhone);
  }

  removeAreaCode (number) {
    if (number[0] === "1" && number[1] === '('){
      return number.substring(1);
    }
    return number;
  }

  handleRenderBusinessInfo (formattedPhone, textableNumber, email) {
    let sameNumber = false;
    if(formattedPhone && textableNumber) {
      sameNumber = this.checkIfNumbersAreTheSame(textableNumber, formattedPhone)
    }
    return (
      <div className='business-wrapper'>
        {
          (formattedPhone && sameNumber) &&
          <div className='business-flex'>
            <FontAwesomeIcon icon={faPhone} />
            <FontAwesomeIcon className="business-margin-left-10" icon={faCommentDots} />
            <p className='business-margin-left-10 business-margin-bottom-0'>Call or text us at {this.removeAreaCode(formattedPhone)}</p>
          </div>
        }
        {
          (formattedPhone && !sameNumber) &&
          <div className='business-flex'>
            <FontAwesomeIcon icon={faPhone} />
            <p className='business-margin-left-10 business-margin-bottom-0'>Call us at {this.removeAreaCode(formattedPhone)}</p>
          </div>
        }
        {(textableNumber && !sameNumber) &&
          <>
            <div className='business-divider'>
              |
            </div>
            <div className='business-flex'>
              <FontAwesomeIcon icon={faCommentDots} />
              <p className='business-margin-left-10 business-margin-bottom-0'>Text us at {this.removeAreaCode(textableNumber)}</p>
            </div>
          </>
        }
        {email &&
          <>
            <div className='business-divider'>
              |
            </div>
            <div className='business-flex'>
              <FontAwesomeIcon icon={faEnvelope} />
              <p className='business-margin-left-10 business-margin-bottom-0 '>Email us at <span className='business-email'>{email}</span></p>
            </div>
          </>
         }
      </div>
    )
  }

  render () {
    let businessInfo = window.portalSession.affiliateBusiness;
    let portalType = (window.portalSession.affiliateBusiness.industryName === 'vet')
    const formattedPhone = numberToPhone(businessInfo.mainPhone)
    let textableNumber = "";
    if (window.portalSession.affiliateBusiness.textableLandLineAssignment) {
      textableNumber = numberToPhone(window.portalSession.affiliateBusiness.textableLandLineAssignment.toString())
    }
    const {email, address1, city, state, postalCode, name} = businessInfo
   
    return (
      <div>
        <div className={`dashboardHeader ${portalType ? 'petHeader' : ''}`}>
          <div className='businessInfo'>
            <span className="businessInfo__main-title">{window.portalSession.portalBusiness.tagline}</span>
            <div>
              <p className='businessHeader '>{address1 && `${address1}, ` }{city && `${city}, `}{state && `${state} `} {postalCode && postalCode}</p>
              {this.handleRenderBusinessInfo(formattedPhone, textableNumber, email)}
            </div>
          </div>
          <div className="mobileBusinessInfo">
            <h1 className='business-margin-right-15'>{name}</h1>
            <p className='businessHeader business-margin-bottom-10'>{address1 && `${address1}` }</p>
            <p className='business-margin-bottom-10'>{city && `${city}, `}{state && `${state} `} {postalCode && postalCode}</p>
            <p className='business-email'>{email}</p>
            <div className='business-contact'>
              {formattedPhone && 
                <a className='business-phone' href={`tel:${formattedPhone}`}>
                  <span className='business-margin-right-10'><FontAwesomeIcon icon={faPhone} /></span>
                  Call Us
                </a>
              }
              {textableNumber && <a className='business-phone' href={`sms:${textableNumber}`}>
                <span className='business-margin-right-10'><FontAwesomeIcon icon={faCommentDots} /></span>
                  Text Us
                </a>
              }
            </div>
          </div>
        </div>
        <div className='dashboardContainer'/>
      </div>
    )
  }
}

export default Dashboard
