import React from 'react';
import Modal from 'react-modal';
import {render} from 'react-dom';
import _ from 'lodash';

const customStyles = {
  overlay : {
    backgroundColor       : 'rgba(0,0,0,0.5)',
    zIndex                : 105,
    overflow              : 'scroll'
  },
  content : {
    padding               : '0',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    overflow              : 'visible',
    transform             : 'translate(-50%, -50%)',
    boxShadow             : '0 2px 44px 0 rgba(0,0,0,0.5)'
  }
};

class DOMWrapper  extends React.Component {
  componentDidMount() {
    this.root.appendChild(this.props.modal);
  }

  render() {
    return <div ref={(root) => {this.root = root}}/>;
  }
}

class FNWrapper  extends React.Component {
  componentDidMount() {
    this.props.modal(this.root);
  }

  render() {
    return <div ref={(root) => {this.root = root}}/>;
  }
}

class AppModal extends React.Component {
  
  componentDidMount() {
    this.setState(window.portalSession.modals.addModalListener(this.setState.bind(this)));
  }

  render () {
    if(this.state && this.state.modals.length > this.state.show) {
      return (
        <div>
          {
            this.state.modals.map((modal, index) => {
              if(index === this.state.show) {
                return (
                  <Modal isOpen={true} style={customStyles}>
                    {_.isElement(modal) ? 
                      <DOMWrapper modal={modal}/> :
                      (_.isFunction(modal) ? 
                        <FNWrapper modal={modal}/> : 
                        modal
                      )
                    }
                  </Modal>
                );
              }
              else {
                return <Modal isOpen={false} style={customStyles}/>;
              }
            })
          }
        </div>
      );
    }
    else {
      return null;
    }
  }
}

export default AppModal
