import React from 'react'
import ButtonSelect from './ButtonSelect'

class Location extends React.Component {
  constructor (props) {
    super(props)
    // this will come from somewhere else later
    this.onSwitchSelect = this.onSwitchSelect.bind(this)
    this.state = {
      options: [
        {
          id: 1,
          text: 'Los Angeles, CA',
          selected: true
        },
        {
          id: 2,
          text: 'Austin, TX',
          selected: false
        },
        {
          id: 3,
          text: 'San Francisco, CA',
          selected: false
        }
      ]
    }
  }
  onSwitchSelect (selected) {
    this.setState((state) => {
      state.options = state.options.map((option) => {
        if (option.id === selected) {
          option.selected = true
        } else {
          option.selected = false
        }
        return option
      })
    })
  }
  componentDidMount () {
    // temporary code
    let s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCW-bpMHKwvNfNgtrgnI9YuL8JS96npBmQ&callback=initMap'
    s.async = true
    s.defer = true
    document.getElementsByTagName('html')[0].appendChild(s)
    window.initMap = function () {
      let map = new google.maps.Map(document.getElementById('location-map'), {
        center: {lat: -34.397, lng: 150.644},
        zoom: 8
      })
    }
    //end of temporary code
  }
  render () {
    return (
      <div className='location-component'>
        <div className='location-header'>Location &amp; Directions</div>
        <div className='location-info'>
          <div className='location-title'>West Side Dentistry</div>
          <div className='location-address'>
            <div>8924 Sepulveda Blvd. Suite 894</div>
            <div>Los Angeles Ca. 90029</div>
          </div>
          <div className='location-phone'>(310)-389-1048</div>
        </div>
        <div>
          { /* Need to create dropdown button */ }
          <ButtonSelect options={this.state.options} onSelect={this.onSwitchSelect} />
        </div>
        <div id='location-map' className='location-point-map' />
      </div>
    )
  }
}

export default Location
