export const defaultThemes = {
           // inject:js
                                                                                      "patient-portal-theme":{
  "--welcome-modal-button-active-image-color":"linear-gradient(126deg, #0089ff, #2D34E5)",
  "--welcome-modal-button-active-bg-color":"0",
  "--welcome-modal-button-hover-bg-color":"shade(#1565c0, 20%)",
  "--platform-bg-color":"#f8f9fb",
  "--platform-button-shadow":"0 2px 1px 0 rgba(57, 83, 119, 0.1)",
  "--sidebar-navigation-background":"linear-gradient(162deg, #37489e 0%, #1a2965 30%, #0d1f50 65%, #112c5e 79%, #1c4685 100%) no-repeat",
  "--sidebar-navigation-background-fallback":"#37489e",
  "--sidebar-navigation-toggle-color":"#dce0e9",
  "--sidebar-navigation-link-background-active":"#1C2353",
  "--sidebar-navigation-link-background-active-border-radius":"0",
  "--sidebar-navigation-link-not-active-icon-color":"#a0b2ce",
  "--sidebar-navigation-link-label-color":"#ffffff",
  "--sidebar-navigation-link-icon-color-active":"#14afff",
  "--sidebar-navigation-link-label-color-active":"#ffffff",
  "--sidebar-navigation-link-icon-color-hover":"#14afff",
  "--sidebar-navigation-link-label-color-hover":"#ffffff",
  "--sidebar-navigation-menu-text-color-active":"#ccd2e0",
  "--sidebar-navigation-mobile-label-color":"#5679c4",
  "--sidebar-navigation-mobile-label-icon-color":"#5679c4",
  "--sidebar-navigation-border-right-color":"0",
  "--header-background-color":"#ffffff",
  "--header-practice-name-text-color":"#339",
  "--hedaer-practice-name-font-family":"DINBold",
  "--hedaer-practice-name-font-weight":"600",
  "--hedaer-practice-name-font-size":"16px",
  "--header-mobile-background-image":"linear-gradient(263deg, rgba(48, 34, 173, 0), rgba(48, 35, 173, 0.01), rgba(46, 129, 197, 0.64)), linear-gradient(97deg, #083cb8, #070052), linear-gradient(#30415c, #30415c)",
  "--header-mobile-background-color":"0",
  "--header-mobile-request-appointment-color":"#9EA8C7",
  "--header-mobile-user-notification-color":"#9EA8C7",
  "--header-desktop-user-notification-color":"#9EA8C7",
  "--header-username-color":"#5679c4",
  "--header-username-caret-color":"#6681B9",
  "--notification-user-background-color":"#E5eaee",
  "--notification-user-border":"none",
  "--notification-user-email-color":"#19396c",
  "--notification-user-text-color":"#333333",
  "--notification-section-color":"#5679c4",
  "--notification-modal-bg-color":"#eff2fa",
  "--notification-list-border-color":"#edf1f4",
  "--notification-status-read-color":"#ffffff",
  "--setting-container-span-color":"#58799f",
  "--setting-header-background-color":"#F3F7FE",
  "--setting-header-active-color":"#2E34E4",
  "--setting-header-inactive-color":"#9EA8C7",
  "--setting-content-title-text-color":"#7d808e",
  "--setting-content-title-subtext-color":"#333333",
  "--account-setting-pricing-switcher-background-image-on":"linear-gradient(126deg, #2697fb, #4d52e8)",
  "--account-setting-pricing-switcher-background-color-on":"0",
  "--account-setting-pricing-switcher-background-off":"#a1a2a5",
  "--account-setting-switch-text-inactive-color":"#7d808e",
  "--account-setting-switch-text-active-color":"#58799f",
  "--account-setting-switch-border-color":"#e1e7ed",
  "--account-setting-opt-in-button-background-image":"linear-gradient(126deg, #2697fb, #4d52e8)",
  "--account-setting-opt-in-button-background-color":"0",
  "--account-setting-update-password-button-background-image":"linear-gradient(126deg, #2697fb, #4d52e8)",
  "--account-setting-update-password-button-background-color":"0",
  "--account-setting-header-text-color":"#19396c",
  "--loading":"#999999",
  "--widget-header-text-color":"#19396c",
  "--widget-header-view-all-color":"#5679c4",
  "--appointment-header-bg-color":"#F9FAFC",
  "--schedule-appt-button-primary-confirm-button-image":"linear-gradient(134deg, #0089ff, #2E34E4)",
  "--schedule-appt-button-primary-confirm-button-color":"0",
  "--appointments-button-second-print-background-color":"0",
  "--appointments-button-second-print-border":"1px solid #5679c4",
  "--appointments-button-second-request-appointment-background-color":"#ffffff",
  "--appointments-button-second-request-appointment-border":"1px solid #1f559b",
  "--appointments-button-text-color":"#1565c0",
  "--appointments-widget-text-color":"#1D3F65",
  "--appointments-widget-subtext-color":"#626972",
  "--appointments-widget-button-bg-img-color":"linear-gradient(126deg, #0089ff, #2D34E5)",
  "--appointments-widget-button-bg-color":"none",
  "--appointments-widget-button-bg-img-hover-color":"linear-gradient(126deg, #2697fb, #4d52e8)",
  "--appointments-widget-button-bg-img-active-color":"linear-gradient(126deg, #026cdc, #272bc8)",
  "--appointments-widget-button-bg-hover-color":"none",
  "--appointments-widget-button-bg-active-color":"none",
  "--appointment-month-header-text-color":"#58799f",
  "--appointments-signup-modal-title-color":"#5679C5",
  "--appointments-signup-modal-text-color":"#848699",
  "--appointments-signup-modal-button-bg":"none",
  "--appointments-signup-modal-button-img":"linear-gradient(110deg, #0089ff, #2D34E5)",
  "--appointments-signup-modal-button-cancel":"#5679c4",
  "--schedule-appt-thnku-modal-header-color":"#2A2C33",
  "--schedule-appt-thnku-modal-button-background-image":"linear-gradient(105deg, #4428b8, #026cdc)",
  "--schedule-appt-thnku-modal-button-background":"0",
  "--schedule-appt-thnku-modal-button-color":"#ffffff",
  "--secure-messaging-send-button-active-bg-image":"linear-gradient(137deg, #0089ff, #2E34E4)",
  "--secure-messaging-send-button-active-bg-color":"0",
  "--secure-messaging-flash-message-bg-image":"linear-gradient(137deg, #0089ff, #2E34E4)",
  "--secure-messaging-flash-message-bg-color":"0",
  "--secure-messaging-widget-create-button-text-color":"#5679c4",
  "--secure-messaging-widget-create-button-border-color":"1px solid #88b7fb",
  "--secure-messaging-widget-create-button-bg-color":"#ffffff",
  "--secure-messaging-widget-navigation-icon-color":"#90ee90",
  "--secure-messaging-widget-read-button-color":"#2045B9",
  "--secure-messaging-create-message-button-shadow":"0 2px 1px 0 rgba(58, 83, 119, 0.07)",
  "--secure-form-app-bg-color":"none",
  "--secure-form-footer-button-submit-image":"linear-gradient(133deg, #0089ff, #2D34E5)",
  "--secure-form-footer-button-submit-color":"0",
  "--secure-form-footer-button-cancel-text-color":"#2045B9",
  "--secure-form-header-text-color":"#339",
  "--secure-form-title-text-color":"#1D3F65",
  "--secure-form-title-dropdown-text-color":"#5679c4",
  "--secure-form-input-focus-color":"#2E34E4",
  "--secure-form-row-text-color":"#1D3F65",
  "--secure-form-new-container-text-color":"0",
  "--secure-form-header-bg-color":"#F9FAFC",
  "--secure-form-widget-form-text-color":"#1D3F65",
  "--secure-form-upload-button-border":"solid",
  "--secure-form-upload-button-bg-color":"#ffffff",
  "--secure-form-upload-button-text-color":"#BCBFCC",
  "--secure-form-question-group-text-color":"#58799f",
  "--secure-form-header-box-shadow":"inset 0px 1px 2px 0px #E6E6E6",
  "--secure-form-widget-footer-button-bg-color":"none",
  "--secure-form-widget-footer-button-img-color":"linear-gradient(133deg, #0089ff, #2D34E5)",
  "--secure-form-widget-footer-button-hover-img-color":"linear-gradient(133deg, #0089ff, #2D34E5)",
  "--secure-form-widget-footer-button-hover-bg-color":"none",
  "--secure-form-widget-footer-cancel-text-hover-color":"#ffffff",
  "--secure-form-widget-footer-secondary-button-text-color":"#1565c0",
  "--secure-form-widget-footer-secondary-button-hover-img-color":"linear-gradient(133deg, #0089ff, #2D34E5)",
  "--secure-form-widget-footer-secondary-button-hover-text-color":"#ffffff",
  "--secure-form-sucess-modal-button-bg-color":"#1565c0",
  "--secure-form-sucess-modal-button-bg-hover-color":"#11519a",
  "--secure-form-sucess-modal-button-bg-active-color":"#1565c0"},"pet-portal-theme":{
  "--welcome-modal-button-active-image-color":"0",
  "--welcome-modal-button-active-bg-color":"#F57F17",
  "--welcome-modal-button-hover-bg-color":"#F58F36",
  "--platform-bg-color":"#FAFAFA",
  "--platform-button-shadow":"none",
  "--sidebar-navigation-background":"#FEFCF9",
  "--sidebar-navigation-background-fallback":"#37489e",
  "--sidebar-navigation-toggle-color":"#FEAB2D",
  "--sidebar-navigation-link-background-active":"#FFECCF",
  "--sidebar-navigation-link-background-active-border-radius":"0 17px 17px 0",
  "--sidebar-navigation-link-not-active-icon-color":"#B4AFA4",
  "--sidebar-navigation-link-icon-color":"#FEAB2D",
  "--sidebar-navigation-link-label-color":"#ffffff",
  "--sidebar-navigation-link-icon-color-active":"#FEAB2D",
  "--sidebar-navigation-link-label-color-active":"#FEAB2D",
  "--sidebar-navigation-link-icon-color-hover":"#FEAB2D",
  "--sidebar-navigation-link-label-color-hover":"#FEAB2D",
  "--sidebar-navigation-menu-text-color-active":"#F57F17",
  "--sidebar-navigation-mobile-label-color":"#483E34",
  "--sidebar-navigation-mobile-label-icon-color":"#B4AFA4",
  "--sidebar-navigation-border-right-color":"1px solid #E1E7ED",
  "--header-background-color":"#ffffff",
  "--header-practice-name-text-color":"#19233C",
  "--hedaer-practice-name-font-family":"proximanova",
  "--hedaer-practice-name-font-weight":"0",
  "--hedaer-practice-name-font-size":"15px",
  "--header-mobile-background-image":"0",
  "--header-mobile-background-color":"#F57F17",
  "--header-mobile-request-appointment-color":"#4D7CFE",
  "--header-mobile-user-notification-color":"#ffffff",
  "--header-desktop-user-notification-color":"#608BFF",
  "--header-username-color":"#5679C3",
  "--header-username-caret-color":"#6681B9",
  "--notification-user-background-color":"none",
  "--notification-user-email-color":"#A4A8B4",
  "--notification-user-border":"1px solid #FEAB2D",
  "--notification-user-text-color":"#2F3034",
  "--notification-section-color":"#9DA6C1",
  "--notification-modal-bg-color":"#FEFCF9",
  "--notification-list-border-color":"#E9EEF2",
  "--notification-status-read-color":"#FEFCF9",
  "--setting-container-span-color":"#A4A8B4",
  "--setting-header-background-color":"#FCFCFC",
  "--setting-header-active-color":"#4D7CFE",
  "--setting-header-inactive-color":"#A4A8B4",
  "--setting-content-title-text-color":"#5F6066",
  "--setting-content-title-subtext-color":"#5F6066",
  "--account-setting-pricing-switcher-background-image-on":"0",
  "--account-setting-pricing-switcher-background-color-on":"#4D7CFE",
  "--account-setting-pricing-switcher-background-off":"#A4A8B4",
  "--account-setting-switch-text-inactive-color":"#A4A8B4",
  "--account-setting-switch-text-active-color":"#5F6066",
  "--account-setting-switch-border-color":"#E5E9EC",
  "--account-setting-opt-in-button-background-image":"none",
  "--account-setting-opt-in-button-background-color":"#F57F17",
  "--account-setting-update-password-button-background-image":"none",
  "--account-setting-update-password-button-background-color":"#F57F17",
  "--account-setting-header-text-color":"#2F3034",
  "--loading":"#FEAB2D",
  "--widget-header-text-color":"#19233C",
  "--widget-header-view-all-color":"#608BFF",
  "--appointment-header-bg-color":"#FCFCFC",
  "--schedule-appt-button-primary-confirm-button-image":"0",
  "--schedule-appt-button-primary-confirm-button-color":"#F57F17",
  "--appointments-button-second-print-background-color":"#ECF3FE",
  "--appointments-button-second-print-border":"0",
  "--appointments-button-second-request-appointment-background-color":"#ECF3FE",
  "--appointments-button-second-request-appointment-border":"0",
  "--appointments-button-text-color":"#4D7CFE",
  "--appointments-widget-text-color":"#2F3034",
  "--appointments-widget-subtext-color":"#5F6066",
  "--appointments-widget-button-bg-img-color":"none",
  "--appointments-widget-button-bg-color":"#F57F17",
  "--appointments-widget-button-bg-hover-color":"#F58F36",
  "--appointments-widget-button-bg-active-color":"#F57300",
  "--appointments-widget-button-bg-img-hover-color":"none",
  "--appointments-widget-button-bg-img-active-color":"none",
  "--appointment-month-header-text-color":"#5F6066",
  "--appointments-signup-modal-title-color":"#4D7CFE",
  "--appointments-signup-modal-text-color":"#5F6066",
  "--appointments-signup-modal-button-bg":"#F57F17",
  "--appointments-signup-modal-button-img":"none",
  "--schedule-appt-thnku-modal-header-color":"#4D7CFE",
  "--appointments-signup-modal-button-cancel":"#608BFF",
  "--schedule-appt-thnku-modal-button-background-image":"0",
  "--schedule-appt-thnku-modal-button-background":"#ECF3FE",
  "--schedule-appt-thnku-modal-button-color":"#4D7CFE",
  "--secure-messaging-send-button-active-bg-image":"none",
  "--secure-messaging-send-button-active-bg-color":"#F57F17",
  "--secure-messaging-flash-message-bg-image":"none",
  "--secure-messaging-flash-message-bg-color":"#FEAB2D",
  "--secure-messaging-widget-create-button-border-color":"0",
  "--secure-messaging-widget-create-button-text-color":"#4D7CFE",
  "--secure-messaging-widget-create-button-bg-color":"#ECF3FE",
  "--secure-messaging-widget-navigation-icon-color":"#FEAB2D",
  "--secure-messaging-widget-read-button-color":"#FEAB2D",
  "--secure-messaging-create-message-button-shadow":"none",
  "--secure-form-app-bg-color":"#ffffff",
  "--secure-form-footer-button-submit-image":"none",
  "--secure-form-footer-button-submit-color":"#F57F17",
  "--secure-form-footer-button-cancel-text-color":"#1F45B9",
  "--secure-form-header-text-color":"#2F3034",
  "--secure-form-title-text-color":"#2F3034",
  "--secure-form-title-dropdown-text-color":"#4D7CFE",
  "--secure-form-input-focus-color":"#4D7CFE",
  "--secure-form-row-text-color":"#2F3034",
  "--secure-form-new-container-text-color":"#5F6066",
  "--secure-form-header-bg-color":"#FCFCFC",
  "--secure-form-widget-form-text-color":"#5F6066",
  "--secure-form-upload-button-border":"none",
  "--secure-form-upload-button-bg-color":"#ECF3FE",
  "--secure-form-upload-button-text-color":"#5679c4",
  "--secure-form-question-group-text-color":"#A4A8B4",
  "--secure-form-header-box-shadow":"none",
  "--secure-form-widget-footer-button-bg-color":"#F57F17",
  "--secure-form-widget-footer-button-img-color":"none",
  "--secure-form-widget-footer-button-hover-img-color":"none",
  "--secure-form-widget-footer-button-hover-bg-color":"#F58F36",
  "--secure-form-widget-footer-cancel-text-hover-color":"#ffffff",
  "--secure-form-widget-footer-secondary-button-text-color":"#1F45B9",
  "--secure-form-widget-footer-secondary-button-hover-img-color":"none",
  "--secure-form-widget-footer-secondary-button-hover-text-color":"#1F45B9",
  "--secure-form-sucess-modal-button-bg-color":"#F57F17",
  "--secure-form-sucess-modal-button-bg-hover-color":"#F58F36",
  "--secure-form-sucess-modal-button-bg-active-color":"#F57300"},"sesame-portal-theme":{
  "--welcome-modal-button-active-image-color":"linear-gradient(126deg, #0089ff, #2D34E5)",
  "--welcome-modal-button-active-bg-color":"0",
  "--welcome-modal-button-hover-bg-color":"shade(#1565c0, 20%)",
  "--platform-bg-color":"#f8f9fb",
  "--platform-button-shadow":"0 2px 1px 0 rgba(57, 83, 119, 0.1)",
  "--sidebar-navigation-background":"linear-gradient(162deg, #0073a5 0%, #009ce0 30%, #009ce0 65%, #009ce0 79%, #d3dc3e 100%) no-repeat",
  "--sidebar-navigation-background-fallback":"#37489e",
  "--sidebar-navigation-toggle-color":"#dce0e9",
  "--sidebar-navigation-link-background-active":"#0e506c",
  "--sidebar-navigation-link-background-active-border-radius":"0",
  "--sidebar-navigation-link-not-active-icon-color":"#f1f6f8",
  "--sidebar-navigation-link-label-color":"#ffffff",
  "--sidebar-navigation-link-icon-color-active":"#14afff",
  "--sidebar-navigation-link-label-color-active":"#ffffff",
  "--sidebar-navigation-link-icon-color-hover":"#14afff",
  "--sidebar-navigation-link-label-color-hover":"#ffffff",
  "--sidebar-navigation-menu-text-color-active":"#f1f6f8",
  "--sidebar-navigation-mobile-label-color":"#5679c4",
  "--sidebar-navigation-mobile-label-icon-color":"#5679c4",
  "--sidebar-navigation-border-right-color":"0",
  "--header-background-color":"#ffffff",
  "--header-practice-name-text-color":"#339",
  "--hedaer-practice-name-font-family":"DINBold",
  "--hedaer-practice-name-font-weight":"600",
  "--hedaer-practice-name-font-size":"16px",
  "--header-mobile-background-image":"linear-gradient(263deg, rgba(48, 34, 173, 0), rgba(48, 35, 173, 0.01), rgba(46, 129, 197, 0.64)), linear-gradient(97deg, #009ce0, #009ce0), linear-gradient(#009ce0, #009ce0)",
  "--header-mobile-background-color":"0",
  "--header-mobile-request-appointment-color":"#9EA8C7",
  "--header-mobile-user-notification-color":"#9EA8C7",
  "--header-desktop-user-notification-color":"#9EA8C7",
  "--header-username-color":"#5679c4",
  "--header-username-caret-color":"#6681B9",
  "--notification-user-background-color":"#E5eaee",
  "--notification-user-border":"none",
  "--notification-user-email-color":"#19396c",
  "--notification-user-text-color":"#333333",
  "--notification-section-color":"#5679c4",
  "--notification-modal-bg-color":"#eff2fa",
  "--notification-list-border-color":"#edf1f4",
  "--notification-status-read-color":"#ffffff",
  "--setting-container-span-color":"#58799f",
  "--setting-header-background-color":"#F3F7FE",
  "--setting-header-active-color":"#2E34E4",
  "--setting-header-inactive-color":"#9EA8C7",
  "--setting-content-title-text-color":"#7d808e",
  "--setting-content-title-subtext-color":"#333333",
  "--account-setting-pricing-switcher-background-image-on":"linear-gradient(126deg, #2697fb, #4d52e8)",
  "--account-setting-pricing-switcher-background-color-on":"0",
  "--account-setting-pricing-switcher-background-off":"#a1a2a5",
  "--account-setting-switch-text-inactive-color":"#7d808e",
  "--account-setting-switch-text-active-color":"#58799f",
  "--account-setting-switch-border-color":"#e1e7ed",
  "--account-setting-opt-in-button-background-image":"linear-gradient(126deg, #2697fb, #4d52e8)",
  "--account-setting-opt-in-button-background-color":"0",
  "--account-setting-update-password-button-background-image":"linear-gradient(126deg, #2697fb, #4d52e8)",
  "--account-setting-update-password-button-background-color":"0",
  "--account-setting-header-text-color":"#19396c",
  "--loading":"#999999",
  "--widget-header-text-color":"#19396c",
  "--widget-header-view-all-color":"#5679c4",
  "--appointment-header-bg-color":"#F9FAFC",
  "--schedule-appt-button-primary-confirm-button-image":"linear-gradient(134deg, #0089ff, #2E34E4)",
  "--schedule-appt-button-primary-confirm-button-color":"0",
  "--appointments-button-second-print-background-color":"0",
  "--appointments-button-second-print-border":"1px solid #5679c4",
  "--appointments-button-second-request-appointment-background-color":"#ffffff",
  "--appointments-button-second-request-appointment-border":"1px solid #1f559b",
  "--appointments-button-text-color":"#1565c0",
  "--appointments-widget-text-color":"#1D3F65",
  "--appointments-widget-subtext-color":"#626972",
  "--appointments-widget-button-bg-img-color":"linear-gradient(126deg, #0089ff, #2D34E5)",
  "--appointments-widget-button-bg-color":"none",
  "--appointments-widget-button-bg-img-hover-color":"linear-gradient(126deg, #2697fb, #4d52e8)",
  "--appointments-widget-button-bg-img-active-color":"linear-gradient(126deg, #026cdc, #272bc8)",
  "--appointments-widget-button-bg-hover-color":"none",
  "--appointments-widget-button-bg-active-color":"none",
  "--appointment-month-header-text-color":"#58799f",
  "--appointments-signup-modal-title-color":"#5679C5",
  "--appointments-signup-modal-text-color":"#848699",
  "--appointments-signup-modal-button-bg":"none",
  "--appointments-signup-modal-button-img":"linear-gradient(110deg, #0089ff, #2D34E5)",
  "--appointments-signup-modal-button-cancel":"#5679c4",
  "--schedule-appt-thnku-modal-header-color":"#2A2C33",
  "--schedule-appt-thnku-modal-button-background-image":"linear-gradient(105deg, #4428b8, #026cdc)",
  "--schedule-appt-thnku-modal-button-background":"0",
  "--schedule-appt-thnku-modal-button-color":"#ffffff",
  "--secure-messaging-send-button-active-bg-image":"linear-gradient(137deg, #0089ff, #2E34E4)",
  "--secure-messaging-send-button-active-bg-color":"0",
  "--secure-messaging-flash-message-bg-image":"linear-gradient(137deg, #0089ff, #2E34E4)",
  "--secure-messaging-flash-message-bg-color":"0",
  "--secure-messaging-widget-create-button-text-color":"#5679c4",
  "--secure-messaging-widget-create-button-border-color":"1px solid #88b7fb",
  "--secure-messaging-widget-create-button-bg-color":"#ffffff",
  "--secure-messaging-widget-navigation-icon-color":"#90ee90",
  "--secure-messaging-widget-read-button-color":"#2045B9",
  "--secure-messaging-create-message-button-shadow":"0 2px 1px 0 rgba(58, 83, 119, 0.07)",
  "--secure-form-app-bg-color":"none",
  "--secure-form-footer-button-submit-image":"linear-gradient(133deg, #0089ff, #2D34E5)",
  "--secure-form-footer-button-submit-color":"0",
  "--secure-form-footer-button-cancel-text-color":"#2045B9",
  "--secure-form-header-text-color":"#339",
  "--secure-form-title-text-color":"#1D3F65",
  "--secure-form-title-dropdown-text-color":"#5679c4",
  "--secure-form-input-focus-color":"#2E34E4",
  "--secure-form-row-text-color":"#1D3F65",
  "--secure-form-new-container-text-color":"0",
  "--secure-form-header-bg-color":"#F9FAFC",
  "--secure-form-widget-form-text-color":"#1D3F65",
  "--secure-form-upload-button-border":"solid",
  "--secure-form-upload-button-bg-color":"#ffffff",
  "--secure-form-upload-button-text-color":"#BCBFCC",
  "--secure-form-question-group-text-color":"#58799f",
  "--secure-form-header-box-shadow":"inset 0px 1px 2px 0px #E6E6E6",
  "--secure-form-widget-footer-button-bg-color":"none",
  "--secure-form-widget-footer-button-img-color":"linear-gradient(133deg, #0089ff, #2D34E5)",
  "--secure-form-widget-footer-button-hover-img-color":"linear-gradient(133deg, #0089ff, #2D34E5)",
  "--secure-form-widget-footer-button-hover-bg-color":"none",
  "--secure-form-widget-footer-cancel-text-hover-color":"#ffffff",
  "--secure-form-widget-footer-secondary-button-text-color":"#1565c0",
  "--secure-form-widget-footer-secondary-button-hover-img-color":"linear-gradient(133deg, #0089ff, #2D34E5)",
  "--secure-form-widget-footer-secondary-button-hover-text-color":"#ffffff",
  "--secure-form-sucess-modal-button-bg-color":"#1565c0",
  "--secure-form-sucess-modal-button-bg-hover-color":"#11519a",
  "--secure-form-sucess-modal-button-bg-active-color":"#1565c0"}

                                                                                     // end:inject:js
}
