import React from 'react'
import PropTypes from 'prop-types';


class ToggleSwitch extends React.Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  static get propTypes() {
    return {
      className: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string,
      value: PropTypes.string
    }
  }

  static get defaultProps() {
    return {
      name: Math.random().toString(16),
      items: [
        {
          value: 'on',
          label: 'ON'
        },
        {
          value: 'off',
          label: 'OFF'
        }]
    }
  }

  onChange (e) {
    const value = e.currentTarget.value
    this.props.onChange.call(this, value)
  }

  render () {
    let toggleOnOff = this.props.value == 'on' ? 'on' : 'off'

    return (
      <div className='cd-pricing-switcher'>
        <p className={`fieldset ${toggleOnOff}`}>
          {this.props.items.map((item, i) => {
            const id = `toggle-switch-${this.props.name}-${item.value}--id`
            return (
            [
              <input key={item.value + '--value'} type='radio' name={this.props.name} value={item.value} onChange={this.onChange} checked={this.props.value === item.value} id={id} />,
              <label key={item.value + '--label'} htmlFor={id}>{item.label}</label>
            ]
            )
          })}
          <span className='cd-switch' />
        </p>
        <span className={`toggle-text ${toggleOnOff} mobile`}>{this.props.text}</span>
      </div>
    )
  }
}

export default ToggleSwitch
