import React from 'react'
import ReactDOM from 'react-dom'
import validator from 'validator'
import { saveConsumer } from '../utils/saveConsumer'
import Countries from './Countries'
import _ from 'lodash'
import Dropdown from './Dropdown'

class Address extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      editAddress: false
    }
    this.onChange = this.onChange.bind(this)
    this.updateStates = this.updateStates.bind(this)
    this.maskAddress = this.maskAddress.bind(this)
  }

  editAddressInfo () {
    let consumer = window.portalSession.affiliateConsumer
    this.setState({
      editAddress: true,
      address1: consumer.address1,
      address2: consumer.address2 || '',
      city: consumer.city,
      state: consumer.state,
      postalCode: consumer.postalCode
    })
  }

  onChange (type, event) {
    this.setState({ [type]: event.target.value })
  }

  updateStates (option) {
    this.setState({ state: option })
  }

  saveAddress () {
    let update = {
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      postalCode: this.state.postalCode
    }

    saveConsumer(update, function (err, res) {
      if (!err) {
        Object.assign(window.portalSession.affiliateConsumer, update)
        this.setState({ editAddress: false, serverError: false })
      } else {
        this.setState({ serverError: true })
      }
    }.bind(this))
  }

  maskAddress (address) {
    return address ? address.replace(/\S/g, 'X') : ''
  }

  render () {
    const { adminUserFlag } = this.props
    return (
      <div>
        <div className='setting-container separate-line'>
          <span>{this.props.label ? this.props.label : "Address"}</span>
          {this.state.editAddress ? null : <span className='edit mobile' onClick={this.editAddressInfo.bind(this)}>Edit</span>}
          {this.state.editAddress
            ? null
          : <div className='setting-content'>
              {this.props.sublabel ?
                <div className= 'setting-text'>You will receive postcards at</div>
                :null
              }
            <div className='setting-text'>Address 1</div>
            <div>{adminUserFlag ? this.maskAddress(window.portalSession.affiliateConsumer.address1) : window.portalSession.affiliateConsumer.address1}</div>
            {this.state.address2
              ? <div>
                <div className='setting-text'>Address 2</div>
                <div>{adminUserFlag ? this.maskAddress(window.portalSession.affiliateConsumer.address2) : window.portalSession.affiliateConsumer.address2}</div>
              </div>
              : null }
            <div>
              <table>
                <tbody>
                  <tr>
                    <th className='setting-text'>City</th>
                    <th className='setting-text'>State</th>
                    <th className='setting-text'>Zip</th>
                  </tr>
                  <tr>
                    <td>{adminUserFlag ? this.maskAddress(window.portalSession.affiliateConsumer.city) : window.portalSession.affiliateConsumer.city}</td>
                    <td>{adminUserFlag ? this.maskAddress(window.portalSession.affiliateConsumer.state) : window.portalSession.affiliateConsumer.state}</td>
                    <td>{adminUserFlag ? this.maskAddress(window.portalSession.affiliateConsumer.postalCode) : window.portalSession.affiliateConsumer.postalCode}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div><a className='button--secondary edit desktop' onClick={this.editAddressInfo.bind(this)}>Edit</a></div>
          </div>
          }
        </div>
      </div>
    )
  }
}

export default Address
