import _ from 'lodash'

export function findStartingRoute (routes, routeName) {
	let route = _.find(routes, {routeURI: routeName || window.location.pathname.split('/')[1] || 'dashboard'})
  if(!route || route.displayOrder < 0) {
    route = _.find(routes, {routeURI: 'Error404'})
  }

  return route;
}

export function numberToPhone( number ) {
	if( number ) {
		return number.replace( /\D+/g, "" ).replace( /([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "($1) $2-$3" ); //mask numbers (xxx) xxx-xxxx
	} else {
		return "";
	}
}

export function appEnabled (app) {
  let smbPortalApps = window?.portalSession?.smbApplications
  return smbPortalApps[app] && smbPortalApps[app].application_is_enabled
}
