import { connect } from 'react-redux'
import _ from 'lodash'
import App from '../components/App'
import { findCurrentPath, loadPlatform, setMainPath, setModalName, toggleMenu } from '../actions/index'

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.routing.get('loading'),
    menuCollapsed: state.menu.get('collapsed'),
    modalName: state.modal.get('modalName'),
    mainPath: state.routing.get('mainPath'),
    successResponse: state.routing.get('successResponse'),
    routesLoaded: state.routing.get('routesLoaded'),
    pageNotFound: state.routing.get('pageNotFound')
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    findCurrentPathLocation: (routesLoaded, routes) => {
      let mainPath = window.location.pathname.split('/')[1]
      
      let route = _.find(routes, {routeURI: mainPath || 'dashboard'})
      if(!route || route.displayOrder < 0) {
        mainPath = 'Error404'
        route = _.find(routes, {routeURI: mainPath})
      }

      dispatch(setMainPath({
        mainPath,
        pathName: window.location.pathname,
        source: '_browser',
        route,
        isAlreadyLoaded: !!routesLoaded.get(mainPath)
      }))
    },
    loadPlatformData: () => {
      dispatch(loadPlatform())
    },
    toggleMenuCollapse: () => {
      dispatch(toggleMenu())
    },
    updateModalName: (modalName) => {
      dispatch(setModalName({ modalName }))
    },
    changeRoute: (routesLoaded, routes, app, appPath, source) => {

      let route = _.find(routes, {routeURI: app})
      if(!route || route.displayOrder < 0) {
        // unknown route, ignore
        return
      }

      dispatch(setMainPath({
        routeMain: route.routeURI,
        pathName: appPath ? `/${route.routeURI}/${appPath}` : `/${route.routeURI}`,
        source,
        route,
        isAlreadyLoaded: !!routesLoaded.get(route.routeURI)
      }))
    },
    updateMainPath: (routesLoaded, route) => {
      let pathName = `/${route.routeURI}`
      if (window.location.pathname !== pathName) {
        dispatch(setMainPath({
          routeMain: route.routeURI,
          pathName,
          source: '_platform',
          route,
          isAlreadyLoaded: !!routesLoaded.get(route.routeURI)
        }))
      }
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    loading: stateProps.loading,
    menuCollapsed: stateProps.menuCollapsed,
    modalName: stateProps.modalName,
    mainPath: stateProps.mainPath,
    successResponse: stateProps.successResponse,
    routesLoaded: stateProps.routesLoaded,
    findCurrentPathLocation: dispatchProps.findCurrentPathLocation,
    loadPlatformData: dispatchProps.loadPlatformData,
    toggleMenuCollapse: dispatchProps.toggleMenuCollapse,
    updateModalName: dispatchProps.updateModalName,
    pageNotFound: stateProps.pageNotFound,
    changeRoute: (routes, app, appPath, source) => {
      dispatchProps.changeRoute(stateProps.routesLoaded, routes, app, appPath, source)
    },
    updateMainPath: (route) => {
      dispatchProps.updateMainPath(stateProps.routesLoaded, route)
    }
  }
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(App)

export default AppContainer
