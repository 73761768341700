import fetch from 'isomorphic-fetch'
import _ from 'lodash'
import $ from 'jquery'
import { call, fork, put } from 'redux-saga/effects'
import { takeEvery } from 'redux-saga'
import * as types from '../actionTypes/index'
import { findStartingRoute } from '../utils/helpers'
import ReactDOM from 'react-dom'
import React from 'react'
import Settings from '../components/Settings'
import Notifications from '../components/Notifications'
import Dashboard from '../components/Dashboard'
import Spinner from '../components/Spinner'
import Error404 from '../components/Error404'
import moment from 'moment'
import request from 'superagent'
import WelcomeModal from '../components/WelcomeModal'
import DependentModal from '../components/DependentModal'
import cssVariables from 'css-vars-ponyfill';
import {defaultThemes} from "../defaultThemes";

const unauthorized = (err) => {
  if(err.status === 401) {
    window.location = window.portalSession.loginURL;
  }
  throw err;
} 

// General function to convert api response from promise to { response, error } for saga
export function fetchApi (url, method = 'GET', body = {}) {
  let fetchDetails = {
    method
  }
  if (method !== 'GET') {
    fetchDetails.body = body
    fetchDetails.headers = {
      'Content-Type': 'application/json'
    }
  }
  fetchDetails.credentials = 'same-origin'

  return fetch(url, fetchDetails)
    .then(response => {
      return response.json().then(json => ({json, response}))
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        if (response.status === 401 && json.err === 'notLoggedIn') {
          window.location.assign(json.loginURL)
        }
        if (response.status === 401 &&
          json.err === 'portalConsumerNotVerified' ||
          response.status === 404 &&
          json.err === 'portalConsumerNotFound') {
          window.location.assign(json.signupURL)
          window.affiliateConsumerNotFound = ''
        } else if (response.status === 404 &&
          json.err === 'affiliateConsumerNotFound') {
          window.jsonData = json
        }
        return Promise.reject(json)
      }

      return json
    })
    .then(
        response => ({response}),
        error => {
          return {
            error: error.message || error.err || 'Something bad happened',
          }
        }
    )
}

// DEPRECATED - moving under portalSession
window.getConsumerPortalAuth = () => {
  if (new Date().getTime() + 30000 > window.portalSession.auth.expires) {
    return fetchApi(`/api/v0/userinfo?includeAuthToken=true`)
      .then((result) => {
        window.portalSession.auth = result.response.auth
        return window.portalSession.auth.consumerPortalAuth
      })
  } else {
    return Promise.resolve(window.portalSession.auth.consumerPortalAuth)
  } 
}

// DEPRECATED - moving under portalSession
window.getIBCAccessToken = () => {
  if (new Date().getTime() + 30000 > window.portalSession.auth.expires) {
    return fetchApi(`/api/v0/userinfo?includeAuthToken=true`)
      .then((result) => {
        let token = result.response.auth.token
        window.portalSession.auth = result.response.auth
        return token
      })
  } else {
    return Promise.resolve(window.portalSession.auth.token)
  }
}

export function createDivs (routes) {
  routes = !!routes ? routes : ''
  for (var i = 0; i < routes.length; i++) {
    let route = routes[i]
    
    var appDiv = null; 
    var wrapperDiv = null;
    if (route.displayOrder >= 0 || route.routeURI === 'Error404') {
      // Create app div if in menu or Error404
      appDiv = document.createElement('div')
      ReactDOM.render(<div className='centerContainer'><Spinner size="lg" /></div>, appDiv);

      wrapperDiv = document.createElement('div')
      wrapperDiv.setAttribute('id', routes[i].routeURI)
      wrapperDiv.appendChild(appDiv)
    }

    // Load basic property keys
    window.portal[route.routeURI] = {
      div: appDiv,
      wrapperDiv: wrapperDiv,
      pushState: (path) => {
        if (path.startsWith('/')) {
          path = path.substring(1)
        }

        let index = path.indexOf('/')
        let app = null
        let appPath = null
        if (index === -1) {
          app = path
          appPath = ''
        } else {
          app = path.substring(0, index)
          appPath = path.substring(index + 1)
        }

        window.dispatchEvent(new CustomEvent('routeChangeRequest', {
          detail: {
            eventType: 'pushstate',
            source: route.routeURI,
            path: path,
            app: app,
            appPath: appPath
          }
        }))
      },
      onRouteChange: (callback, includeBrowser = false) => {
        window.portal[route.routeURI]._onRouteChange = callback
        window.portal[route.routeURI]._includeBrowser = includeBrowser
      }
    }
  }

  let divCounts = {};

  for(let i = 0; i < window.portalSession.dashboardConfig.length; i++) {
    let column = window.portalSession.dashboardConfig[i]
    for(let j = 0; j < column.length; j++) {
      if(window.portal[column[j]]) {
        let dashboardDiv = document.createElement('div')
        dashboardDiv.className = 'dashboardDiv'

        let appName = column[j].trim();
        let divCount = divCounts[appName] = (divCounts[appName] || 0) + 1;

        if(divCount === 1) {
          divCount = '';
        }
        ReactDOM.render(<div className='dashboardBorder' id={`${appName}Dashboard${divCount}`}><div className='centerContainer'><Spinner size="lg" /></div></div>, dashboardDiv)
        window.portal[column[j]][`dashboardDiv${divCount}`] = dashboardDiv
      }
    }
  }

  for(let i = 0; i < window.portalSession.headerConfig.length; i++) {
    if(window.portal[window.portalSession.headerConfig[i]]) {
      let headerDiv = document.createElement('div')
      headerDiv.className = 'headerDiv'
      window.portal[window.portalSession.headerConfig[i]].headerDiv = headerDiv
    }
  }

  window.addEventListener('routeChange', (event) => {
    let portalApp = window.portal[event.detail.app]
    if (portalApp && portalApp._onRouteChange) {
      if (portalApp._includeBrowser || event.detail.source !== '_browser') {
        portalApp._onRouteChange(event.detail.path, event.detail.source)
      }

      if (portalApp.isIframe) {
        if (window.location.pathname !== event.detail.path) {
          window.history.pushState({}, event.detail.path, event.detail.path)
        }
      }
    } else {
      if (window.location.pathname !== event.detail.path) {
        window.history.pushState({}, event.detail.path, event.detail.path)
      }
    }
  })
}

export function fetchApp (route, delay=1000) {

  // Load Script
  if (route?.routeURI != undefined) {
    if (route.routeURI === 'Error404') {
      ReactDOM.render(<Error404 />, window.portal.Error404.div)
      return
    }
    // possible if route = affiliateConsumerNotFound
    if (route.routeURI === 'settings') {
      ReactDOM.render(<Settings />, window.portal.settings.div)
      return
    }
    else if(route.routeURI === 'notifications') {
      ReactDOM.render(<Notifications/>, window.portal.notifications.div)
      return
    }
    else if (route.routeURI === 'dashboard') {
      ReactDOM.render(<Dashboard />, window.portal.dashboard.div)
      return
    }
  }

  let useiframe = false
  if (!useiframe || !route.packageURL) {
     if (route?.requiresInit && !route.initComplete) {

       setTimeout(() => {
         fetchApi(`/api/v0/userinfo?includeMenuOptions=true&subdomain=${window.location.hostname}`)
         .then((result) => {
            if(result.response.menuOptions) {
              let option = _.find(result.response.menuOptions, {routeURI: route.routeURI});
              if(option.initComplete) {
                loadScript(route.packageURL, function () {})
                return;
              }
            }
            throw 'not complete'
          })
         .catch ((error) => {
               setTimeout(() => {
                 fetchApp(route, Math.min(delay + 1000, 10000))
               }, delay)
          })
      }, delay)
    } else loadScript(route?.packageURL, function () {})
    return
  }

  var frame = document.createElement('iframe')
  frame.src = 'about:blank'
  frame.width = '100%'
  frame.height = '100%'
  frame.frameBorder = 0
  frame.style.position = 'absolute'

  window.portal[route.routeURI].div.appendChild(frame)
  window.portal[route.routeURI].isIframe = true

  frame.onload = () => {
    var doc = frame.contentDocument

    doc.open()
    doc.write(`
      <div id='app'></div>
      <script>
        window.portalSession = window.parent.portalSession;
        window.getIBCAccessToken = window.parent.getIBCAccessToken;
        window.getConsumerPortalAuth = window.parent.getConsumerPortalAuth;
        window.portal = {
          ${route.routeURI}: {
            getPathname: function() {
              return window.parent.location.pathname;
            },
            getSearch: function() {
              return window.parent.location.search;
            },
            div: document.getElementById('app'),
            pushState: window.parent.portal.${route.routeURI}.pushState,
            onRouteChange: window.parent.portal.${route.routeURI}.onRouteChange
          }
        }; 
      </script>
      <script src='${route.packageURL}'></script>`)
    doc.close()
  }

  if (frame.contentWindow) {
    frame.contentWindow.location.reload()
  }
}

export function * setMainPath (action) {
  let { isAlreadyLoaded, mainPath, pathName, route, source } = action.payload
  let setRoute = route
  if (isAlreadyLoaded) {
    yield put({
      type: types.FETCH_APP_SUCCESS,
      routeName: setRoute.routeURI
    })

    window.dispatchEvent(new CustomEvent('routeChange', {
      detail: {
        eventType: 'pushstate',
        path: pathName,
        app: route.routeURI,
        appPath: pathName.substring(route.routeURI.length + 2),
        source
      }
    }))
  } else {
    if (window.location.pathname !== pathName) {
      window.history.pushState({}, pathName, pathName)
    }

    yield put({ type: types.FETCH_APP_REQUEST })
    yield call(fetchApp, setRoute)
    yield put({
      type: types.FETCH_APP_SUCCESS,
      routeName: setRoute.routeURI
    })
  }
}

export function loadScript (scriptPath, callback) {
  if (scriptPath) {
    $.ajax({
      type: 'GET',
      url: scriptPath,
      success: callback,
      dataType: 'script',
      cache: true
    })
  } else {
    callback()
  }
}

export function * fetchNavigationRoutes () {
  yield put({ type: types.FETCH_NAVIGATION_ROUTES_REQUEST })

  // let domainArray = window.location.host.split('.')
  const { response, error } = yield call(fetchApi,
    `/api/v0/userinfo?includeAffiliate=true&includeMenuOptions=true&includeAppData=true&includeConsumerEvents=true&includeSMB=true&includeAuthToken=true&subdomain=${window.location.hostname}&includeResponsible=true`)
  let menu = null
  if (!error) {
    response.affiliateConsumer = response.affiliateConsumers[0]
    response.portalConsumer = response.portalConsumers[0]

    menu = [
      {
        'displayOrder': -1000,
        'menuTitle': null,
        'routeURI': 'Error404',
        'packageURL': null
      },
      {
        'displayOrder': 0,
        'menuTitle': 'Home',
        'routeURI': 'dashboard',
        'packageURL': null
      }
    ]
    menu = menu.concat(response.menuOptions).concat([
      {
        'displayOrder': 10000,
        'menuTitle': 'Settings',
        'routeURI': 'settings',
        'packageURL': null
      },
      {
        'displayOrder': 20000,
        'menuTitle': null,
        'routeURI': 'notifications',
        'packageURL': null
      }
    ])

    response.menuOptions = _.sortBy(menu, 'displayOrder')

    window.portalSession = response
    window.portalSession.getConsumerPortalAuth = window.getConsumerPortalAuth
    window.portalSession.getIBCAccessToken = window.getIBCAccessToken
    window.portalSession.dashboardConfig = [['petProfile', 'messaging', 'forms'],['appointments', 'petProfile','financialInfo']]
    window.portalSession.headerConfig = ['appointments']
    window.portalSession.notificationConfig = ['forms', 'messaging', 'appointments'];
    window.portalSession.optinConfig = [
      {app: 'messaging', sectionTitle: 'Secure Message Settings', text: 'Turn on notifications for secure messaging', optin: [{channel:'EMAIL', customerOption: 10114, title: 'Email Notifications'}]},
    ]

    if (!!window.portalSession && window.portalSession?.portalBusiness?.brand === 'dfsesame') { 
      theme = 'sesame-portal-theme'
      cssVariables({
        watch: true,
        variables: defaultThemes[theme],
        onlyLegacy: false
      })
      document.getElementsByTagName('body')[0].setAttribute('theme', theme);
    }
  }
            
  // const { response: routes, error } = {response: routesJSON, error: null}
  if (error) {
    if (error == 'portalBusinessNotFound') {
      yield put({ type: types.LOAD_NOTFOUNDPAGE_REQUEST })
      return
    }
    if (error === 'affiliateConsumerNotFound') {
      window.portalSession = response
      return
    }
    yield put({ type: types.FETCH_NAVIGATION_ROUTES_FAILURE })
  } else {
    yield put({ type: types.FETCH_NAVIGATION_ROUTES_SUCCESS, successResponse: response })
    return menu
  }
}

export function * fetchConsumerEvents () {
  const { response, error } = yield call(fetchApi, `/api/v0/navigation/consumerevents?consumerId=${window.portalSession.portalConsumer.consumerId}`)
  if(error) {
    yield put({ type: types.FETCH_NAVIGATION_ROUTES_FAILURE })
  }

  return response;
}

const updateConsumerEvents = (updates) => {
  let calls = [];

  if(updates.create && updates.create.length > 0) {
    for(let i = 0; i < updates.create.length; i++) {
      let update = updates.create[i]
      calls.push(
        request.post('/api/v0/navigation/consumerevents')
          .send({
            consumerId: update.consumerId,
            app: update.app,
            type: update.type,
            data: update.data,
            eventTime: update.eventTime
          })
      );
    }
  }

  if(updates.delete && updates.delete.length > 0) {
    for(let i = 0; i < updates.delete.length; i++) {
      calls.push(
        request.delete(`/api/v0/navigation/consumerevents/${updates.delete[i]}`)
      );
    }
  }

  if(updates.update && updates.update.length > 0) {
    for(let i = 0; i < updates.update.length; i++) {
      let update = updates.update[i];
      calls.push(
        request.put(`/api/v0/navigation/consumerevents/${update.consumerEventId}`)
          .send({
            eventTime: update.eventTime,
            data: update.data,
            read: update.read,
            deleted: update.deleted
          })
      );
    }
  }

  return Promise.all(calls).catch(unauthorized);
}

const eventsToNotifications = (events, areEqual) => {

  events = events || [];
  let notifications = [];
  let duplicates = [];

  for(let i = 0; i < events.length; i++) {
    let event = events[i];

    event.time = event.eventTime;

    let matchIndex = _.findIndex(notifications, (notification) => {
      return areEqual(event, notification);
    });

    if(matchIndex !== -1) {
      let match = notifications[matchIndex];
      // consumerEventIds are increasing numeric strings but possibly too large to parse as Numbers, compare as strings
      if(event.consumerEventId.length < match.consumerEventId.length || (event.consumerEventId.length === match.consumerEventId.length && event.consumerEventId < match.consumerEventId)) {
        // event came first, keep event, delete match
        notifications[matchIndex] = event;
        duplicates.push(match.consumerEventId);
      }
      else {
        // match came first, keep match, delete event
        duplicates.push(event.consumerEventId);
      }
    }
    else {
      // keep it
      notifications.push(event);
    }
  }

  return {notifications, duplicates};
}

/*
   get remote - if two identical notifications (app,type,eventType,data) keep lowest id, remote delete others and render
   manage update with no creates - update local notifications then render
   manage update with creates - do remote then get remote
   update notification, update remote and update local

   (always do get after create in case same event create twice)
*/
const processNotifications = (app, reload=false) => {
  
  let appNotifications = window.portal[app].notifications;
  
  if(reload) {
    let reloadCount = appNotifications._reloadCount || 0;
    appNotifications._reloadCount = ++reloadCount;
    appNotifications._reloading = true;
    request.get(`/api/v0/navigation/consumerevents?consumerId=${window.portalSession.portalConsumer.consumerId}&app=${app}`)
      .catch(unauthorized)
      .then((events) => {
        if(appNotifications._reloadCount === reloadCount) {
          // another reload has not been requested, take results
          appNotifications._reloading = false;
          window.portalSession.notifications.events[app] = events.body;
          processNotifications(app);
        }
      })
      .catch((err) => {
        if(appNotifications._reloadCount === reloadCount) {
          // reload failed, schedule reload after wait so we don't hog cpu if something is down
          setTimeout(() => {
              processNotifications(app, true);
            }, 1000);
        }
      })

    return;
  }
  
  if(appNotifications._reloading) {
    // currently reloading, let reload finish
    return;
  }

  let manage = appNotifications._manage;
  let data = appNotifications._data;
  
  let {notifications: previousNotifications, duplicates: toDelete} =  eventsToNotifications(window.portalSession.notifications.events[app], manage.areEqual);
  previousNotifications.sort((notification1, notification2) => {
    let time1 = new moment(notification1.time);
    let time2 = new moment(notification2.time);

    if(time1.isBefore(time2)) {
      return -1;
    }
    else if(time1.isAfter(time2)) {
      return 1;
    }
    else {
      return 0;
    }
  });

  let nextNotifications = manage.generate(data, 'en-US', previousNotifications);
  let toCreate = [];
  let toKeep = [];
  for(let i = 0; i < nextNotifications.length; i++) {
    let nextNotification = nextNotifications[i];
    nextNotification.app = app;

    let matchIndex = _.findIndex(previousNotifications, (notification) => {
      return notification && manage.areEqual(nextNotification, notification);
    });

    if(matchIndex !== -1) {
      // already exists, keep it
      let previousNotification = previousNotifications[matchIndex];
      previousNotification.title = nextNotification.title;
      previousNotification.body = nextNotification.body;
      previousNotification.link = nextNotification.link;
      toKeep.push(previousNotification);

      previousNotifications[matchIndex] = null;
    }
    else {
      // new event
      nextNotification.eventTime = nextNotification.time;
      delete nextNotification.time;
      nextNotification.consumerId = window.portalSession.portalConsumer.consumerId;
      toCreate.push(nextNotification);
    }
  }

  // check for more to delete
  if(toKeep.length < previousNotifications.length) {
    for(let i = 0; i < previousNotifications.length; i++) {
      if(previousNotifications[i]) {
        toDelete.push(previousNotifications[i].consumerEventId);
      }
    }
  }

  if(toCreate.length > 0 || toDelete.length > 0) {
    updateConsumerEvents({
      create: toCreate,
      delete: toDelete
    })
    .then(() => {
      processNotifications(app, true);
    })
    .catch(() => {
      processNotifications(app, true);
    })
  }
  else {
    // steady state, render

    toKeep = _.filter(toKeep, {deleted: false});
    toKeep = _.map(toKeep, (notification) => {
      notification = _.assign(manage.forRender(notification, 'en-US'), notification);
      notification.time = new moment(notification.time);
      if(!notification.link) {
        notification.link = `/${app}`
      }
      return notification;
    });

    _.remove(window.portalSession.notifications.notifications, {app});
    window.portalSession.notifications.notifications.push(...toKeep);

    for(let i = 0; i < window.portalSession.notifications._notificationListeners.length; i++) {
      window.portalSession.notifications._notificationListeners[i]({notifications: window.portalSession.notifications.notifications});
    }
  }
}

/*
    window.portal.appointments.notifications.manage({
      generate: (appointments, locale, previousNotifications) => {

        // filter appointments based on appointment scheduledDate and status
        appointments = _.filter(appointments, (appointment) => {...});

        // map each appointment to a notification 
        return appointments.map((appointment) => {
          return {
            app: 'appointments',
            type: 'scheduled',
            time: appointment.modifiedDate, 
            data: {
              id: appointment.id,
              scheduledDate: appointment.scheduledDate
            }
          };
        });
      },
      forRender: (notification, locale) => {
        return {
          title: 'Appointment',
          body: `You have a upcoming appointment on ${new moment(event.data.scheduledDate)}`
        };
      }
    });

    // load appointments
    getAppointments()
      .then(window.portal.appointments.notifications.update);
*/
const setupNotification = (app) => {
  window.portalSession.notifications.events[app] = window.portalSession.notifications.events[app] || [];

  let notifications = window.portal[app].notifications = {
    manage: (manage) => {
      if(_.isFunction(manage)) {
        manage = {
          generate: manage
        };
      }

      if(!manage.areEqual) {
        manage.areEqual = (notification1, notification2) => {
          return notification1.type === notification2.type && 
            _.isEqual(notification1.data, notification2.data);
        };
      }

      if(!manage.forRender) {
        manage.forRender = (notification) => {
          return notification;
        };
      }

      if(manage.generate && manage.waitForUpdate !== false) {
        manage.waitForUpdate = true;
      }

      notifications._manage = manage;

      if(!manage.waitForUpdate) {
        processNotifications(app);
      }
    },
    update: (data) => {
      notifications._data = data;
      processNotifications(app);
    }
  };
}

const PROVIDER_TYPE = {
  dental: 'dentist',
  optometric: 'optometrist',
  chiropratic: 'chiropractor',
  vet: 'veterinarian'
}

const CONSUMER_TYPE = {
  vet: 'consumer'
}

const PORTAL_TYPE = {
  vet: 'pet'
}

// Usually a chain/list of functions to call various endpoints
export function * loadPlatform () {
  const routes = yield call(fetchNavigationRoutes)

  !window.portalSession && window.portalSession === undefined && window?.jsonData?.err === "affiliateConsumerNotFound" ? window.portalSession = {
    smbApplications: '',
    portalType: '',
    consumerType: '',
    providerType: '',
    affiliateBusiness: window.jsonData?.affiliateBusiness,
    affiliateConsumer: {id: ''},
    portalBusiness: {
      industryName: '',
    },
    portalConsumer: {
      brandConsumerId: ''
    },
    responsible: {
      responsibleForCustomers: ''
    },
    consumerEvents: '',
    dashboardConfig: '',
    headerConfig: '',
    notificationConfig: '',
    logoutUrl: window?.jsonData?.logoutUrl,
    affiliateConsumerNotFound: 'affiliateConsumerNotFound'
  } : window.portalSession
  
  // TODO: move to app
  window.smbApps = window?.portalSession?.smbApplications;

  // market mapping
  window.portalSession.portalType = PORTAL_TYPE[window.portalSession.portalBusiness.industryName] || 'patient'
  window.portalSession.consumerType = CONSUMER_TYPE[window.portalSession.portalBusiness.industryName] || 'patient'
  window.portalSession.providerType = PROVIDER_TYPE[window.portalSession.portalBusiness.industryName] || 'doctor'

  // set modals
  const modals = window.portalSession.modals = {
    _modals: [],
    _show: 0,
    _modalListeners: [],
    showModal: (modal) => {
      modals._modals.push(modal);
      for(let i = 0; i < modals._modalListeners.length; i++) {
        modals._modalListeners[i]({
          modals: modals._modals,
          show: modals._show
        });
      }

      return () => {
        modals._show++;
        for(let i = 0; i < modals._modalListeners.length; i++) {
          modals._modalListeners[i]({
            modals: modals._modals,
            show: modals._show
          });
        }
      }
    },
    addModalListener: (listener) => {
      modals._modalListeners.push(listener);
      return {
        modals: modals._modals,
        show: modals._show
      };
    }
  };

  const petProfilesUpload = () =>{
    if(window.portalSession.portalBusiness.industryName === 'vet'){
      //fire event if this is pet portal
      var event = document.createEvent('Event');
      event.initEvent('PetProfiles', true, true);
      document.dispatchEvent(event);
    }
  }

  // Welcome Modal
  if(window.portalSession.responsible && (window.portalSession.responsible.id == window.portalSession.portalConsumer.brandConsumerId)){
    if(!window.portalSession.portalConsumer.firstLogin) {
      // move to welcome component, set firstLogin:true on click
        let done = window.portalSession.modals.showModal(<WelcomeModal done={() => {done(); petProfilesUpload();}}/>);
    }
  }

  if(window.portalSession.responsible.responsibleForCustomers.length ){
    let hasDependents = false
    hasDependents = window.portalSession.responsible.responsibleForCustomers.map((dependent)=>{
      if(!dependent.blocked){
        if(dependent.patientPortalId){
          return false
        }else return true
      }else{
        return false
      }
    })    
    if((hasDependents.indexOf(true) !== -1) && (window.portalSession.portalConsumer.rpClose === null) && !sessionStorage.getItem('remindModal')){
      // move to DependentModal component, set rpClose:true on click
      let done = window.portalSession.modals.showModal(<DependentModal done={() => {done()}}/>);
    }
  }

  // setup logout
  window.portalSession.logout = () => {
    for(let i = 0; i < logoutListeners.length; i++) {
      // TODO: Promise?
      logoutListeners[i]();
    }
    sessionStorage.removeItem('remindModal');
    window.location.assign(window.portalSession.logoutURL);
  }

  const logoutListeners = [];
  window.portalSession.onLogout = (listener) => {
    logoutListeners.push(listener);;
  }


  // setup events and notifications
  const notifications = window.portalSession.notifications = {
    events: {},
    notifications: [],
    _notificationListeners: [],
    addNotificationListener: (listener) => {
      notifications._notificationListeners.push(listener);
      return {notifications: notifications.notifications};
    },
    notificationToggleRead: (notification, e) => {
      updateConsumerEvents({
        update: [{
          consumerEventId: notification.consumerEventId,
          read: !notification.read
        }]
      })
      .then(() => {
        processNotifications(notification.app, true);
      })
      .catch(() => {
        processNotifications(notification.app, true);
      })
    },
    notificationDelete: (notification) => {
      updateConsumerEvents({
        update: [{
          consumerEventId: notification.consumerEventId,
          deleted: true
        }]
      })
      .then(() => {
        processNotifications(notification.app, true);
      })
      .catch(() => {
        processNotifications(notification.app, true);
      })
    }
  };

  for(let i = 0; i < window.portalSession.consumerEvents.length; i++) {
    let event = window.portalSession.consumerEvents[i];

    let appEvents = notifications.events[event.app];
    if(!appEvents) {
      appEvents = notifications.events[event.app] = [];
    }
    
    appEvents.push(event);
  }

  yield put({ type: types.FETCH_APP_REQUEST })
  yield call(createDivs, routes)

  for(let i = 0; i < window.portalSession.notificationConfig.length; i++) {
    let appName = window.portalSession.notificationConfig[i]
    if(window.portalSession.apps[appName] && window.portalSession.menuOptions.filter(i=>i.routeURI === appName).length > 0) {
      setupNotification(appName);
    }
  }

  // keep track of what apps are loading
  let loading = {};

  // load starting route
  const startingRoute = findStartingRoute(routes, window.location.pathname.split('/')[1])
  yield call(fetchApp, startingRoute)
  yield put({
    type: types.FETCH_APP_SUCCESS,
    routeName: startingRoute?.routeURI
  })

  loading[startingRoute?.routeURI] = true

  // load dashboard apps
  for(let i = 0; i < window.portalSession.dashboardConfig.length; i++) {
    let column = window.portalSession.dashboardConfig[i]
    for(let j = 0; j < column.length; j++) {
      let appRoute = _.find(routes, {routeURI: column[j]})
      if(appRoute && !loading[appRoute.routeURI]) {
        yield call(fetchApp, appRoute)
        yield put({
          type: types.LOAD_APP_SUCCESS,
          routeName: appRoute.routeURI
        })

        loading[appRoute.routeURI] = true
      }
    }
  }

  // load header apps
  for(let i = 0; i < window.portalSession.headerConfig.length; i++) {
    let appRoute = _.find(routes, {routeURI: window.portalSession.headerConfig[i]})
    if(appRoute && !loading[appRoute.routeURI]) {
      yield call(fetchApp, appRoute)
      yield put({
        type: types.LOAD_APP_SUCCESS,
        routeName: appRoute.routeURI
      })

      // loading.push(appRoute.routeURI);
    }
  }
}

// Watchers for actions
export function * watchLoadPlatform () {
  yield call(takeEvery, types.LOAD_PLATFORM_REQUEST, loadPlatform)
}
export function * watchSetMainPath (action) {
  yield call(takeEvery, types.SET_MAIN_PATH, setMainPath)
}

export default function * root () {
  yield [
    fork(watchLoadPlatform),
    fork(watchSetMainPath)
  ]
}
