import React from 'react'
import DeactivatePet from './../../images/deactivate-pet.png'
import graph from 'ib-graph'

class DeactivatePetModal extends React.Component {
  constructor (props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
    this.deactivatePet = this.deactivatePet.bind(this)
  }

  hideModal () {
    this.props.done()
  }

  deactivatePet () {
    graph.resolve({
      pet: {
        id: this.props.pet.id,
        $update: {
          status: 'DELETED'
        }
      }
    })
    .then(() => {
      this.props.getUpdatedPetList()
      this.props.done()
    })
  }

  render () {
    return (
      <div className='deactivate-pet-modal'>
        <div className='deactivate-pet-modal__image'>
          <img src={DeactivatePet} />
        </div>
        <div className='deactivate-pet-modal__content'>
          <div className='deactivate-pet-modal__content_title'>Deactivate Pet</div>
          <div className='deactivate-pet-modal-wrapper'>
              <ul className='deactivate-pet-modal__content_list'>
                <p>Deactivating pet will disable the following features regarding {this.props.pet.name}:</p>
                <li>Receiving and viewing updates</li>
                <li>Sending and receiving messages</li>
                <li>Completing forms</li>
                <li>Requesting appointment </li>
              </ul>
              <div className='deactivate-pet-modal__content_list-desc'>By clicking Confirm, we will notify { window.portalSession.affiliateBusiness.name } that you no longer have this pet.</div>
          </div>
        </div>
        <div className='deactivate-pet-modal__footer'>
          <button className='cancel-btn' onClick={this.hideModal}>Cancel</button>
          <button className='confirm-btn' onClick={this.deactivatePet}>Confirm </button>
        </div>
      </div>
    )
  }
}
export default DeactivatePetModal
