import React from 'react'
import ReactDOM from 'react-dom'
import validator from 'validator'
import request from 'superagent'
import Emails from './Emails'
import TextMessages from './TextMessages'
import Postcards from './Postcards'
import AppSettings from './AppSettings'
import _ from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/fontawesome-free-solid'
import { faClear } from '@fortawesome/fontawesome-free-solid'
import FlashMessage from './FlashMessage';
const emailStatusOptionType = '19'

class SettingsManageNotifications extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
    this.onToggle = this.onToggle.bind(this)
    this.setCustomerOption = this.setCustomerOption.bind(this)
  }

  // momentary fix, maybe fix adding the customerOptions to redux store?
  setCustomerOption (type, value) {
    this.setState((state) => {
      let customerOptions = _.cloneDeep(state.customerOptions)
      customerOptions[type] = { value: value, type: type }
      return {customerOptions: customerOptions}
    })
  }
  componentDidMount () {
    this.setState({
      customer: this.props.customer,
      status: this.props.status,
      customerOptions: this.props.customerOptions,
      emailOptinStatus: this.props.emailOptinStatus,
      emailOptinProcess:this.props.emailOptinProcess
    })
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      customer: nextProps.customer,
      status: nextProps.status,
      customerOptions: nextProps.customerOptions,
      emailOptinStatus: nextProps.emailOptinStatus,
      emailOptinProcess:nextProps.emailOptinProcess
    })
  }

  onToggle (type, value) {
    let customerOptions = _.cloneDeep(this.state.customerOptions)
    let customerOption = customerOptions[type]
    let emailStatusOption = _.cloneDeep(this.state.emailOptinProcess.optinStatus)
    let requestEmailStatusURL = `/api/v0/d3-api/v1/business/${window.portalSession.affiliateBusiness.id}/customer/${window.portalSession.affiliateConsumer.id}/emailOptinProcess`
    let requestURL = `/api/v0/d3-api/v1/business/${window.portalSession.affiliateBusiness.id}/customerOption`
    let body = null
    const EMAIL_STATUS_OPTION_TYPE = '19'

    if(type == EMAIL_STATUS_OPTION_TYPE){
      emailStatusOption = emailStatusOption == 'optIn' ? 'false' : 'true'
      body = {
        "email" : window.portalSession.affiliateConsumer.email,
        "optIn" :
          {
            "d3": emailStatusOption
          }
      }
    }else if (customerOption) {
      customerOption.value = customerOption.value == '1' ? '0' : '1'

      body = {
        '$update': {
          '$filter': {
            'customerId': Number(window.portalSession.affiliateConsumer.id),
            'type': type
          },
          '$template': { value: customerOption.value }
        }
      }
    } else {
      let newValue = value == '1' ? '0' : '1'
      customerOptions[type] = { value: newValue, type: type }
      body = {
        '$create': {
          '$template': {
            value: newValue,
            'customerId': Number(window.portalSession.affiliateConsumer.id),
            'type': type
          }
        }
      }
    }
    
    this.setState((state) => {
      let customerOptions = _.cloneDeep(state.customerOptions)
      let customerOption = customerOptions[type]
      if (customerOption) {
        customerOptions[type] = { value: (customerOption.value == '1' ? '0' : '1'), type: type }
      } else {
        let newValue = value == '1' ? '0' : '1'
        customerOptions[type] = { value: newValue, type: type }
      }
      return {customerOptions: customerOptions}
    })

    if(type == EMAIL_STATUS_OPTION_TYPE){
      request
        .post(requestEmailStatusURL)
        .send(body)
        .end(function (err, res) {
          if (err) {
            this.setState((state) => {
              this.state.emailOptinStatus = 'error'
              return {emailOptinStatus: 'error'}
            })
          }else{
            this.setState((state) => {
              let emailOptinProcess = _.cloneDeep(this.state.emailOptinProcess)
              emailOptinProcess.optinStatus = emailOptinProcess.optinStatus == 'optIn'? 'optOut' : 'optIn'
              return {emailOptinProcess: emailOptinProcess , changed: true}
            })
          }
        }.bind(this))
    }else{
      request
        .post(requestURL)
        .send(body)
        .end(function (err, res) {
          let customerOptions = _.cloneDeep(this.state.customerOptions)
          let customerOption = customerOptions[type]
          customerOption.value = customerOption.value == '1' ? '0' : '1'
          if (err) {
            this.setState((state) => {
              let customerOptions = _.cloneDeep(state.customerOptions)
              let customerOption = customerOptions[type]
              if (customerOption) {
                customerOptions[type] = { value: (customerOption.value == '1' ? '0' : '1'), type: type }
              }
              return {customerOptions: customerOptions}
            })
          }
        }.bind(this))
        
    }
  }
  hideMessage(){
    this.setState({changed: false});
  }
  render () {
    const { adminUserFlag } = this.props

    let notificationBody = (
      <div>
        <Emails onToggle={this.onToggle} {...this.state} adminUserFlag={adminUserFlag} />
        <TextMessages onToggle={this.onToggle} setCustomerOption={this.setCustomerOption} {...this.state} adminUserFlag={adminUserFlag} />
        <Postcards onToggle={this.onToggle} {...this.state} adminUserFlag={adminUserFlag} />
        <AppSettings onToggle={this.onToggle} {...this.state} />
        {this.state.emailOptinStatus == 'error' ?
            <div className='alt-error'>
              <FontAwesomeIcon icon={faExclamationCircle}/>
              Error! Please try again.
            </div>
          : ''
        }
        {this.state.changed ?
          <FlashMessage message={'You\'ve successfully opted '+((this.state.emailOptinProcess.optinStatus === 'optIn') ? 'in to' : 'out of' )+' email notifications'} hideMessage= {this.hideMessage.bind(this)} />
          :""
        }
      </div>
    )
    
    
    return (
      <div style={this.props.style}>
        { this.state.status == 200
        ? notificationBody
        : <div>Loading</div>
      }
      </div>
    )
  }
}

export default SettingsManageNotifications
