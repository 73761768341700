import request from 'superagent'

export function saveConsumer (update, callback) {
  let requestURL = `/api/v0/d3-api/v1/business/${window.portalSession.affiliateBusiness.id}/customers`
  let body = {
    '$update': {
      '$filter': {
        'id': window.portalSession.affiliateConsumer.id
      },
      '$template': update
    }
  }

  return window.portalSession.getConsumerPortalAuth()
    .then((consumerPortalAuth) => {
      request
        .post(requestURL)
        .send(body)
        .end(callback)
      })
}
