import React from 'react'
import NotificationList from './NotificationList'
import Location from './Location'
import fontawesome from '@fortawesome/fontawesome'
import { faAngleUp, faAngleDown, faCoffee, faCheck } from '@fortawesome/fontawesome-free-solid'

fontawesome.library.add(faAngleUp, faAngleDown, faCoffee, faCheck)

class Notifications extends React.Component {
  constructor (props) {
    super(props)
   
    this.state = {
      notifications: window.portalSession.notifications.notifications
    }
  }

  componentDidMount () {
    window.portalSession.notifications.addNotificationListener(this.setState.bind(this));
  }

  render () {
    return (
      <div className='user-notification-page'>
        <div className='user-notification-page-title'>
          Notification Center
        </div>
        <div className='two-columns'>
          <div className='column-70'>
            <NotificationList minified={0} notifications={this.state.notifications} showAllNotifications={() => { }} />
          </div>
        </div>
      </div>
    )
  }
}

export default Notifications
