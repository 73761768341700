import { fromJS } from 'immutable'
import * as types from '../actionTypes/index'

const initialModalState = fromJS({
  modalName: null,
})

export default function menu (state = initialModalState, action) {
  switch (action.type) {
    case types.SET_MODAL_NAME:
      return state.set('modalName', action.payload.modalName)
    default:
      return state
  }
}
