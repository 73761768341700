import {connect} from 'react-redux'
import Menu from '../components/Menu'
import { setModalName, toggleMenu } from '../actions/index'
import _ from 'lodash'

const mapStateToProps = (state, ownProps) => {
  return {
    modalName: state.modal.get('modalName'),
    menuCollapsed: state.menu.get('collapsed'),
    successResponse: state.routing.get('successResponse'),
    moreMenuOptions: _.filter(state.routing.get('successResponse').toJS().menuOptions, (menuOption) => {return menuOption.menuTitle}).length > 4,
    mainPath: state.routing.get('mainPath'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenuCollapse: () => {
      dispatch(toggleMenu())
    },
    updateModalName: (modalName) => {
      dispatch(setModalName({ modalName }))
    }
  }
}

const MenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu)

export default MenuContainer
