import React from 'react'
import graph from 'ib-graph'
import DeactivatePetModal from '../components/DeactivatePetModal'
import './../../scss/components/settings/_pet-status'

class PetStatus extends React.Component {
  constructor(props) {
    super(props)
    this.openDeactivatePetModal = this.openDeactivatePetModal.bind(this)
    this.getUpdatedPetList = this.getUpdatedPetList.bind(this)
    this.state = {
      pets: []
    }
  }

  componentWillMount() {
    this.getUpdatedPetList()
  }

  getUpdatedPetList() {
    graph.resolve({
      pets: {
        'status': 'ACTIVE',
        $project: [
          'id',
          'status',
          'name'
        ]
      }
    }).then((results) => {
      this.setState({ pets: results.pets })
    })
  }

  openDeactivatePetModal(pet) {
    let done = window.portalSession.modals.showModal(<DeactivatePetModal pet={pet} getUpdatedPetList={() => { this.getUpdatedPetList() }} done={() => { done() }} />)
  }

  renderActivePets(pets) {
    return pets.map((item) => {
      let petName = item.name
      return (
        <div className='pets-list'>
          <div className='pets-list__name'>{petName}</div>
          {
            item.status === 'ACTIVE' &&
            <div className='pets-list__desc' onClick={() => this.openDeactivatePetModal(item)}>I no longer have this pet</div>
          }
          {
            item.status === 'DELETED' &&
            <div className='pets-list__desc'>Please contact your provider if you want to activate this pet again.</div>
          }
        </div>
      )
    })
  }

  render() {
    console.log(this.state.pets)
    let activePets = this.state.pets.filter(pet => pet.status === 'ACTIVE')
    return (
      <div>
        <div className='setting-container separate-line'>
          <span>Pet Status</span>
          <div className='setting-content'>
            <div className='pets active'>
              <span className='pets-label'>Active</span>
              <div>
                {
                  activePets.length === 0 &&
                  <p className='pets-empty'>You currently have no active pets</p>

                }
              </div>
              <div>
                {
                  activePets.length > 0 &&
                  this.renderActivePets(activePets)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PetStatus
