import React from 'react'
import ReactDOM from 'react-dom'

import _ from 'lodash'

class WrapperComponent extends React.Component {
  componentDidMount () {
    const {mainPath} = this.props
    let wrapper = ReactDOM.findDOMNode(this)

    _.keys(window.portal).forEach((appPath) => {
      let wrapperDiv = window.portal[appPath].wrapperDiv
      if(wrapperDiv) {
        wrapper.appendChild(wrapperDiv)
      }
    })
  }
  render () {
    const {mainPath} = this.props
    _.keys(window.portal).forEach((appPath) => {
      let wrapperDiv = window.portal[appPath].wrapperDiv
      if(wrapperDiv) {
        wrapperDiv.style.display = (appPath === mainPath ? 'block' : 'none')
      }
    })
    return <div className='wrapper-container' />
  }
}

export default WrapperComponent
