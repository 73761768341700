import React from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/fontawesome-free-solid'

class CheckBox extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isChecked: props.isChecked
    }
    this.changeCheckBox = this._changeCheckBox.bind(this)
  }
  _changeCheckBox(){
    this.setState({isChecked: !this.state.isChecked},()=>{
      if(this.state.isChecked){
        this.props.onChecked(this.props.bid, 'add')
      }else{
        this.props.onChecked(this.props.bid, 'remove')
      }
    });
  }
  render(){
    return (
      <span className={'check-box-component'}>
          <span onClick={this.props.disabled ? null :this.changeCheckBox} className={this.state.isChecked ? 'checkmark' : 'checkmark notChecked'}>{this.state.isChecked && <FontAwesomeIcon icon={faCheck} />}</span>
          {this.props.children}
      </span>
    )
  }
}

export default CheckBox
