import React from 'react'
import ReactDOM from 'react-dom'
import validator from 'validator'
import { saveConsumer } from '../utils/saveConsumer'
import PasswordSubmitModal from './PasswordSubmitModal'
import PasswordValidationUI from './PasswordValidationUI'
import request from 'superagent'
class Password extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      password: '',
      editPassword: false,
      validPassword: true,
      passwordConfirmation: '',
      validPasswordConfirmation: true,
      updateResponse: true
    }
    this.onChange = this.onChange.bind(this)
    this.savePassword = this.savePassword.bind(this);
  }

  onChange (type, event) {
    this.setState({
      [type]: event.target.value,
      editPassword: true,
      validPassword: true,
      validPasswordConfirmation: true,
    })
  }

  validatePassword () {
    const password = this.state.newPassword
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,20}/
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/

    if (password === '' || !passwordRegex.test(password)) { return false }
    return true
  }

  validatePasswordConfirmation () {
    if (this.state.newPassword !== this.state.passwordConfirmation) { return false }
    return true
  }

  savePassword () {
    if (!this.validatePassword()) {
      this.setState({ validPassword: false })
    }
    else if (!this.validatePasswordConfirmation()) {
      this.setState({ validPasswordConfirmation: false })
    }
    else {
      let body = {
        'currentPassword' : this.state.oldPassword,
        'newPassword' : this.state.newPassword,
        'subdomain'   : window.location.hostname
      }
        request
        .put('api/v0/updatepassword')
        .send(body)
        .then((res)=>{
          if(res.body.updated){
            let done  = window.portalSession.modals.showModal(<PasswordSubmitModal done={() => {done()}}/> );
          }
          else{
            if(res.body.reason == 'repeatedPassword'){
              this.setState({updateResponse: "repeatedPassword"})
            }
            else{
              this.setState({updateResponse: "wrongPassword"})
            } 
          }
          
        })
        .catch((err) => {
          this.setState({updateResponse: 'UnableSave'})
          throw err;
        });
    }
  }

  render () {
    let locals = this.props.locals._component('/platform/settings/accountInfo')
    let consumer = window.portalSession.affiliateConsumer
    //debugger;
    return (
      <div>
        <div className='setting-container'>
          <span>Password</span>
          <div className='setting-text font-weight-normal psdRequire'>
            <b>Requirements:</b><br />
            <PasswordValidationUI password={this.state.newPassword} />
          </div>
          <div className='setting-content'>
            <div className='setting-text'>Enter Old Password*</div>
            <div><input type='password' onChange={this.onChange.bind(this, 'oldPassword')} /></div>
            {this.state.updateResponse
              ? this.state.updateResponse === 'wrongPassword' ?<div className='form__help-text show-error'>Wrong Password</div>:null:null}
            <div className='setting-text'>Enter New Password*</div>
            <div><input type='password' value={this.state.newPassword} onChange={this.onChange.bind(this, 'newPassword')} /></div>
            {this.state.validPassword
              ? null : <div className='form__help-text show-error'>Invalid password</div> }
            {this.state.updateResponse
              ?this.state.updateResponse == 'repeatedPassword' ?<div className='form__help-text show-error'>This Password has been previously used. Please enter a new password.</div>:null:null}
            <div className='setting-text'>Repeat New Password*</div>
            <div><input type='password' value={this.state.passwordConfirmation} onChange={this.onChange.bind(this, 'passwordConfirmation')} /></div>
            <div className='setting-text font-weight-normal'>Please note your password (case-sensitive)</div>
            {this.state.validPasswordConfirmation
              ? null : <div className='form__help-text show-error'>Password does not match</div> }
            { this.state.updateResponse === 'UnableSave'
              ? <div className='form__help-text show-error'>Unable to Update Password. Please try after some time.</div>
              : null
            }
            <br />
            <div>
              {this.state.editPassword
                ? <a className='button--primary desktop' onClick={this.savePassword}>Update Password</a>
                : <button disabled='disabled' className='button--disabled'>Update Password</button>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Password
