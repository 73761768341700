import {combineReducers} from 'redux'
import menu from './menu'
import modal from './modal'
import routing from './routing'

export default combineReducers({
  menu,
  modal,
  routing
})
