import React from 'react'
import ReactDOM from 'react-dom'
import validator from 'validator'
import moment from 'moment'
import { saveConsumer } from '../utils/saveConsumer'
import InputMask from 'react-input-mask';


class PersonalInfo extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      editPersonalInfo: false,
      validFirstName: true,
      validLastName: true,
      validBirthday: true,
      validCellPhone: true
    }
    this.onChange = this.onChange.bind(this)
    this.maskBirthday = this.maskBirthday.bind(this)
    this.maskPhoneNumber = this.maskPhoneNumber.bind(this)
  }

  editPersonalInfo () {
    let consumer = window.portalSession.affiliateConsumer
    let birthday = moment(consumer.birthdate).format('MM/DD/YYYY')

    this.setState({
      editPersonalInfo: true,
      firstName: consumer.firstName,
      lastName: consumer.lastName,
      birthdate: birthday,
      cellPhone: consumer.textablePhone
    })
  }

  onChange (type, event) {
    this.setState({
      [type]: event.target.value.trim(),
      validFirstName: true,
      validLastName: true,
      validBirthday: true,
      validCellPhone: true
    })
  }

  validatePersonalInfo () {
    let personalInfo = {}
    let firstName = this.state.firstName
    if (firstName === '') {
      personalInfo['validFirstName'] = false
    }

    let lastName = this.state.lastName
    if (lastName === '') {
      personalInfo['validLastName'] = false
    }

    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/
    let birthday = this.state.birthdate
    if (birthday === '' || !dateRegex.test(birthday) || !validator.isBefore(birthday)) {
      personalInfo['validBirthday'] = false
    }

    let cellPhone = this.state.cellPhone
    //! validator.isMobilePhone(cellPhone, 'en-US')
    if (cellPhone === '') {
      personalInfo['validCellPhone'] = false
    }

    return personalInfo
  }

  savePersonalInfo () {
    let personalInfo = this.validatePersonalInfo()

    if (!Object.keys(personalInfo).length) {
      let update = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        birthdate: moment(this.state.birthdate).format('YYYY-MM-DD'),
        cellPhone: this.state.cellPhone
      }

      saveConsumer(update, function (err, res) {
        if (!err) {
          Object.assign(window.portalSession.affiliateConsumer, update)
          this.setState({ editPersonalInfo: false, serverError: false })
        } else {
          this.setState({ serverError: true })
        }
      }.bind(this))
    } else {
      this.setState(personalInfo)
    }
  }

  maskBirthday (birthday) {
    if (birthday && birthday.split('/').length > 2) {
      return 'XX/' + 'XX/' + birthday.split('/')[2]
    }
    return birthday
  }

  maskPhoneNumber (phoneNumber) {
    return phoneNumber ? phoneNumber.replace(/[0-9]/g, 'X') : ''
  }

  render () {
    let consumer = window.portalSession.affiliateConsumer
    let birthday = moment(consumer.birthdate).format('MM/DD/YYYY')
    let adminUserFlag = this.props.adminUserFlag

    return (
      <div>
        <div className='setting-container separate-line'>
          <span>Personal Info</span>
          {this.state.editPersonalInfo ? null : <span className='edit mobile' onClick={this.editPersonalInfo.bind(this)}>Edit</span>}
          {this.state.editPersonalInfo
            ? <div className='setting-content'>
              <div className='setting-text'>First Name</div>
              <div><input onChange={this.onChange.bind(this, 'firstName')} value={this.state.firstName} /></div>
              {this.state.validFirstName ? null : <div className='form__help-text show-error'>Invalid First Name</div>}
              <div className='setting-text'>Last Name</div>
              <div><input onChange={this.onChange.bind(this, 'lastName')} value={this.state.lastName} /></div>
              {this.state.validLastName ? null : <div className='form__help-text show-error'>Invalid Last Name</div>}
              <div className='setting-text'>Birthday</div>
              <div><InputMask onChange={this.onChange.bind(this, 'birthdate')} mask="99/99/9999" placeholder='MM/DD/YYYY' value={this.state.birthdate} /></div>
              {this.state.validBirthday ? null : <div className='form__help-text show-error'>Invalid Birthday</div>}
              <div className='setting-text'>Mobile Number</div>
              <div><input onChange={this.onChange.bind(this, 'cellPhone')} value={this.state.cellPhone} /></div>
              {this.state.validCellPhone ? null : <div className='form__help-text show-error'>Invalid Mobile Number</div>}
              {this.state.serverError ? <div className='form__help-text show-error'>There is something wrong. Please try again.</div> : null }
              <br />
              <div>
                <a className='button--primary desktop' onClick={this.savePersonalInfo.bind(this)}>Save Changes</a>
                <a className='cancel desktop'
                  onClick={() => { this.setState({ editPersonalInfo: false, validFirstName: true, validLastName: true, validBirthday: true, validCellPhone: true, serverError: false }) }}>Cancel</a>
              </div>
            </div>
          : <div className='setting-content'>
            <div className='setting-text'>First Name</div>
            <div>{window.portalSession.affiliateConsumer.firstName}</div>
            <br />
            <div className='setting-text'>Last Name</div>
            <div>{window.portalSession.affiliateConsumer.lastName}</div>
            <br />
            <div className='setting-text'>Birthday</div>
            <div>{adminUserFlag ? this.maskBirthday(birthday) : birthday}</div>
            <br />
            <div className='setting-text'>Mobile Number</div>
            <div>{adminUserFlag ? this.maskPhoneNumber(window.portalSession.affiliateConsumer.textablePhone) : window.portalSession.affiliateConsumer.textablePhone}</div>
            <br />
            <div><a className='button--secondary edit desktop' onClick={this.editPersonalInfo.bind(this)}>Edit</a></div>
          </div>
          }
        </div>
      </div>
    )
  }
}

export default PersonalInfo
