import Locals from 'd3_locals-lib'

Locals.configure({
  '/platform': {
    en: {
      edit: 'Edit',
      save: 'Save',
      cancel: 'Cancel'
    },
    es: {
      edit: 'Editar',
      save: 'Salvar',
      cancel: 'Cancelar'
    }
  },
  '/platform/settings': {
    en: {
    	accountSettings: 'Account Settings',
    	generalInfo: 'General Info',
    	desktopNotification: 'Manage Notification',
    	mobileNotification: 'Notification',
    	save: 'Save Changes'
    },
    es: {
    	accountSettings: 'Configuraciones de la cuenta',
    	generalInfo: 'Información general',
    	desktopNotification: 'Administrar notificación',
    	mobileNotification: 'Notificación',
    	save: 'Guardar cambios'
    }
  },
  '/platform/settings/accountInfo': {
  	en: {
  		accountInfo: 'Account Info',
  		loginEmail: 'Login Email',
  		communicationEmail: 'Communication Email'
  	},
  	es: {
  		accountInfo: 'Informacion de cuenta',
  		loginEmail: 'Email de la Iniciar sesión',
  		communicationEmail: 'Correo electrónico de comunicación'
  	}
  }
})

export default Locals
