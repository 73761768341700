import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faEnvelope, faTrashAlt } from '@fortawesome/fontawesome-free-regular'
import { faCheck } from '@fortawesome/fontawesome-free-solid'

class NotificationActions extends React.Component {
  constructor (props) {
    super(props)
    this.renderButtons = this.renderButtons.bind(this)
    this.onHoverRead = this.onHoverRead.bind(this)
    this.onLeaveRead = this.onLeaveRead.bind(this)
    this.buttonReadMessage = this.buttonReadMessage.bind(this)

    this.state = {
      onHoverRead: false
    }
  }
  onHoverRead () {
    if (this.props.minified) return
    this.setState((state) => {
      state.onHoverRead = true
    })
  }
  onLeaveRead () {
    if (this.props.minified) return
    this.setState((state) => {
      state.onHoverRead = false
    })
  }
  buttonReadMessage () {
    if (this.state.onHoverRead) {
      return (
        <div>
          <FontAwesomeIcon icon={faEnvelope} />
          <span>Mark as Unread</span>
        </div>
      )
    } else {
      return (
        <div>
          <FontAwesomeIcon icon={faCheck} />
          <span>Read</span>
        </div>
      )
    }
  }
  renderButtons () {
    if (this.props.read) {
      return (<div className='notification-read' onMouseEnter={this.onHoverRead} onMouseLeave={this.onLeaveRead} onClick={this.props.onToggleRead}>{this.buttonReadMessage()}</div>)
    } else {
      return (<div className='notification-unread' onClick={this.props.onToggleRead}><input type='checkbox' />Mark as Read</div>)
    }
  }
  render () {
    return (
      <div className='notification-status'>
        {this.renderButtons()}
        <div className='notification-delete' onClick={this.props.onDelete}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      </div>
    )
  }
}

export default NotificationActions
