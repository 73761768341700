import React from 'react'
import ReactDOM from 'react-dom'
import ToggleSwitch from './ToggleSwitch'
import { appEnabled } from '../utils/helpers'

class Emails extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
    this.maskEmail = this.maskEmail.bind(this)
  }

  maskEmail (email) {
    if (email && email.split('@').length > 1) {
      return '*'.repeat(email.split('@')[0].length) + '@' + email.split('@')[1]
    }
    return email
  }

  render () {
    let consumer = window.portalSession.affiliateConsumer
    let emailStatus = null

    if (this.props.emailOptinProcess) {
      emailStatus = this.props.emailOptinProcess.optinStatus
      emailStatus = emailStatus == 'optIn' ? '1' : '0'
    }

    let emailReminder = (this.props.customerOptions[10103] || {}).value || '1'
    let emailThankyou = (this.props.customerOptions[10102] || {}).value || '1'
    let emailCustomerPromotions = (this.props.customerOptions[10105] || {}).value || '1'
    let emailNewsletters = (this.props.customerOptions[10106] || {}).value || '1'
    let emailBirthday = (this.props.customerOptions[10107] || {}).value || '1'

    let showEmailTypes = appEnabled('DFAppointment') && appEnabled('DFContact') // show only when contacts 2.0 and appointments are turned on
    const { adminUserFlag } = this.props
    return (
      <div>
        <div className='setting-container separate-line'>
          <span>Emails</span>
          <div className='setting-content'>
            <div className='setting-text'>You will receive emails at</div>
            <div className='margin1'><input className='disabledInputWidth' disabled='disabled' value={ adminUserFlag ? this.maskEmail(consumer.email) : consumer.email} /></div>
            <br />

            <div>
                <div className='setting-text'>Opt-In Status</div>
                <div className='setting-text font-weight-normal separate-line padding1'>Configure your opt-in status</div>
                <ToggleSwitch
                    name='emailStatus'
                    onChange={this.props.onToggle.bind(this, 19, emailStatus)}
                    value={emailStatus == '1' ? 'on' : 'off'}
                    text='Status'
                    emailOptinStatus={this.props.emailOptinStatus}
                />
                <br />
            </div>
            {showEmailTypes &&
            <div>
              <div className='setting-text'>Email Types</div>
              <div className='setting-text font-weight-normal separate-line padding1'>Turn on the Email notification you
                wish to receive
              </div>
              <ToggleSwitch
                name='emailReminder'
                onChange={this.props.onToggle.bind(this, 10103, emailReminder)}
                value={emailReminder == '1' ? 'on' : 'off'}
                text='Appointment Reminders'
              />
              <ToggleSwitch
                name='emailThankyou'
                onChange={this.props.onToggle.bind(this, 10102, emailThankyou)}
                value={emailThankyou == '1' ? 'on' : 'off'}
                text='Thank You'
              />
              <ToggleSwitch
                name='emailCustomerPromotions'
                onChange={this.props.onToggle.bind(this, 10105, emailCustomerPromotions)}
                value={emailCustomerPromotions == '1' ? 'on' : 'off'}
                text='Customer Promotions'
              />
              <ToggleSwitch
                name='emailNewsletters'
                onChange={this.props.onToggle.bind(this, 10106, emailNewsletters)}
                value={emailNewsletters == '1' ? 'on' : 'off'}
                text='Newsletters'
              />
              <ToggleSwitch
                name='emailBirthday'
                onChange={this.props.onToggle.bind(this, 10107, emailBirthday)}
                value={emailBirthday == '1' ? 'on' : 'off'}
                text='Birthday'
              />
              <br/>
            </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Emails
