import React from 'react'
import ReactDOM from 'react-dom'
import validator from 'validator'
import AccountInfo from './AccountInfo'
import PersonalInfo from './PersonalInfo'
import Address from './Address'
import PetStatus from './PetStatus'
import Password from './Password'

class SettingsGenralInfo extends React.Component {
  render () {
    const { adminUserFlag } = this.props
    return (
      <div style={this.props.style}>
        <AccountInfo locals={this.props.locals} adminUserFlag={adminUserFlag} />
        <PersonalInfo locals={this.props.locals} adminUserFlag={adminUserFlag} />
        {
          window.portalSession.portalType === 'pet' &&
          <PetStatus />
        }
        <Address locals={this.props.locals} adminUserFlag={adminUserFlag} />
        <Password locals={this.props.locals} />
      </div>
    )
  }
}

export default SettingsGenralInfo
