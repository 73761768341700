import React from 'react'
import _ from 'lodash'

class Dropdown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {showDropdown: false}
  }

  toggleDropdown () {
    this.setState({showDropdown: !this.state.showDropdown})
  }

  hideDropdown () {
    this.setState({showDropdown: false})
  }

  // This function prevents onBlur from firing when child dropdown node is clicked (Firefox)
  preventBlurOnChild (event) {
    if (_.includes(event.target.className, 'option-choice')) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  render () {
    const {callback, className, options, selected, tabIndexBlur} = this.props
    return (
      <span
        className={((className) ? ` ${className}` : '')}
        tabIndex={tabIndexBlur}
        onBlur={this.hideDropdown.bind(this)}
        onMouseDown={this.preventBlurOnChild.bind(this)}
        onClick={this.toggleDropdown.bind(this)}
      >
        <span className='dropdown-header'>
          {
          (selected) || null
          }
        </span>
        <span className='arrow' />
        {
          this.state.showDropdown
          ? <ul className='dropdown-options'>
            {
              options.map((option) => {
                const optionClassName = (option === selected)
                ? 'option-choice selected'
                : 'option-choice not-selected'
                return (
                  <li
                    key={option}
                    onClick={() => { callback(option) }}
                    className={optionClassName}
                >
                    {option}
                  </li>
                )
              })
            }
          </ul>
          : null
        }
      </span>
    )
  }
}

Dropdown.propTypes = {
}

export default Dropdown
